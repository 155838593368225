import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { Alert, Burst } from 'Utils/SvgIcons';
import CurrencyLabel from 'Utils/CurrencyLabel';
import converter from 'json-style-converter/es5';
import axios from 'axios';
import classnames from 'classnames';
import { getNumberAvailable } from 'HelperFunctions/availability';
import LoadingSpinner from "Utils/LoadingSpinner";

const displayDeliveryType = {
  customer_pick_up: 'pick-up',
  default_delivery: 'delivery',
};

class CartBilling extends React.Component {
  state = {
    salesTaxes: [],
  };

  renderTotalLabel(step) {
    switch (step) {
      case 1:
        return 'Subtotal';
      case 2:
        return 'Running Subtotal';
      case 3:
        return 'Total';
      default:
        return 'Subtotal';
    }
  }

  findBillingLine(kind) {
    const { event } = this.props;
    if (!event.billingLines) {
      return null;
    }
    return event.billingLines.find((line) => line.kind === kind);
  }

  renderBilling = () => {
    const {
      step,
      event,
      location,
      storefrontShopSetting,
      availability,
    } = this.props;

    const rentalInventory = event.inventoryOnCart
      .filter((item) => item._destroy !== '1');

    let processingFee = null;

    const inventoryTotal = this.findBillingLine('inventoryTotal')?.finalValue || 0;
    const damageWaiverFee = this.findBillingLine('damageWaiverFee')?.finalValue || 0;
    const salesTaxesTotal = this.findBillingLine('salesTaxesTotal')?.finalValue || 0;
    const deliveryTotal = this.findBillingLine('deliveryTotal')?.finalValue || 0;
    const subTotal = this.findBillingLine('subtotal')?.finalValue || 0;
    const orderTotal = this.findBillingLine('orderTotal')?.finalValue || 0;

    const hasPriceAvailableOnRequest = rentalInventory.some(
      (item_container) =>
        item_container.selectedPrice === 0 ||
        item_container.selectedPrice === 'N/A'
    );

    const minimumItemTotal =
      event.deliveryType === 'customer_pick_up'
        ? location.minimumItemTotalForPickup
        : location.minimumItemTotalForDelivery;

    const hasMinimumItemTotalAlert =
      !hasPriceAvailableOnRequest &&
      event.deliveryType !== 'none' &&
      inventoryTotal < minimumItemTotal;

    const canAutoBook = event.inventoryOnCart.every((item) => {
      const numberAvailable = getNumberAvailable(
        availability,
        item.id,
        'items'
      );
      return numberAvailable > item.quantity;
    });

    const showPrice = event.additionalInfo?.show_prices

    const { cartHasChanged } = this.props.event;

    if (storefrontShopSetting.shopShowItemPricing && showPrice) {
      return (
        <div className='billingSummary'>

          {
            cartHasChanged && (
              <div className='loadingOverlay'>
                <div>
                  <LoadingSpinner />
                  <p>Calculating totals...</p>
                </div>
              </div>
            )
          }

          <h4>Summary</h4>
          <div className='billingDetails'>
            <div>
              <label className='labelPrimary'>Items Subtotal</label>
              {hasPriceAvailableOnRequest ? (
                <span>Price On Request</span>
              ) : (
                <CurrencyLabel value={inventoryTotal} />
              )}
            </div>
            <div>
              <label className='labelPrimary'>Damage Waiver Fee</label>
              {hasPriceAvailableOnRequest ? (
                <span>Price On Request</span>
              ) : (
                <CurrencyLabel value={damageWaiverFee} />
              )}
            </div>
            <div className='subtotal'>
              <label className='labelPrimary'>Estimated Delivery</label>
              <CurrencyLabel value={deliveryTotal} />
            </div>
            <div>
              <label>Tax</label>
              {step < 3 && !event.editingEvent ? <span>TBD</span> : <CurrencyLabel value={salesTaxesTotal} />}
            </div>
            {step >= 3 && processingFee && (
              <div>
                <label className='labelPrimary'>Processing fee</label>
                <CurrencyLabel value={processingFee} />
              </div>
            )}
            <div className='subtotal'>
              <label className='labelPrimary'>
                {this.renderTotalLabel(step)}
              </label>
              {showPrice ? (
                hasPriceAvailableOnRequest ? (
                  <span>Price On Request</span>
                ) : step >= 3  || event.editingEvent  ? (
                  <CurrencyLabel value={orderTotal} />
                ) : (
                  <CurrencyLabel value={subTotal} />
                )
              ) : (
                <span>TBD</span>
              )}
            </div>
            {hasMinimumItemTotalAlert && (
              <div className='alert'>
                <Alert />
                <p>
                  Your order does not meet the minimum purchase amount for{' '}
                  {displayDeliveryType[event.deliveryType]}. (
                  <CurrencyLabel value={minimumItemTotal} /> min.)
                </p>
              </div>
            )}
          </div>
          <div className='notes'>
            <h4>Notes</h4>
            <ul className='bullets'>
              {step < 3 || event.editingEvent && (
                <li>Tax and Delivery are dependent on Order Info in Step 2.</li>
              )}
              <li>
                Price is subject to change based on delivery costs, other fees,
                and taxes.
              </li>
              {location.shouldAllowAutoBook && !canAutoBook && (
                <li>
                  One or more of your items are unavailable for autobooking. You
                  are still able to submit a quote.
                </li>
              )}
              <li>
                Order Dates are the dates you need the rentals. Delivery dates
                will be set up after your order is created.
              </li>
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className='billingSummary'>
          <h4>Summary</h4>
          <div className='billingDetails'>
            <div>
              <label className='labelPrimary'>Items Subtotal</label>
              <span>TBD</span>
            </div>
            <div>
              <label className='labelPrimary'>Damage Waiver Fee</label>
              <span>TBD</span>
            </div>
            <div>
              <label className='labelPrimary'>Tax</label>
              <span>TBD</span>
            </div>

            <div>
              <label className='labelPrimary'>Estimated Delivery</label>
              <span>TBD</span>
            </div>
            <div className='subtotal'>
              <label className='labelPrimary'>
                {this.renderTotalLabel(step)}
              </label>
              <span>TBD</span>
            </div>
          </div>
          <div className='notes'>
            <h4>Notes</h4>
            <ul className='bullets'>
              {step < 3 && (
                <li>Tax and Delivery are dependent on Order Info in Step 2.</li>
              )}
              <li>
                Price is subject to change based on delivery costs, other fees,
                and taxes.
              </li>
              {location.shouldAllowAutoBook && !canAutoBook && (
                <li>
                  One or more of the items in your rental require approval
                  before you can reserve them. Your quote will be sent for
                  approval when you submit.
                </li>
              )}
              <li>
                Order Dates are the dates you need the rentals. Delivery dates
                will be set up after your order is created.
              </li>
            </ul>
          </div>
        </div>
      );
    }
  };

  render() {
    const { step, newEvent, rental } = this.props;

    return (
      <div
        className={classnames({
          billing: true,
          billingComplete: step === 4,
        })}
      >
        {step === 4 ? (
          !newEvent.name ? (
            <div className='complete'>
              <Burst />
              <p>
                Thank you for your interest in ordering from{' '}
                {this.props.business.name}. {this.props.business.name} will
                reach out for more details to continue the process. In the
                meantime you can keep shopping, as you will receive a
                confirmation email with all of the details of your request.
              </p>
            </div>
          ) : (
            <div className='complete'>
              <h4 id='summarySuccess'>Success!</h4>
              <hr></hr>
              {location.shouldAllowAutoBook ||
              (rental && rental.status === 'reservation') ? (
                <p>We received your order! (#{newEvent.token})</p>
              ) : (
                <p>We received your quote! (#{newEvent.token})</p>
              )}
              <p>
                You should get a confirmation email with all the details, or you
                can manage it{' '}
                <Link className='rentalLink' to={`/events/${newEvent.id}`}>
                  here
                </Link>
                .
              </p>
            </div>
          )
        ) : (
          ''
        )}

        {step < 4 && this.renderBilling()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const storefrontShopSetting = parsedStorefrontSettings.storefrontShopSetting;
  const { event, newEvent } = state.cart;
  const { availability } = state;
  const rental = state.rental.rental;
  return {
    location,
    event,
    newEvent,
    storefrontShopSetting,
    availability,
    rental,
  };
};

export default withRouter(connect(mapStateToProps, actions)(CartBilling));
