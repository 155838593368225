import React from "react";
import DatePicker from "Utils/DatePicker";
import TimePicker from "Utils/TimePicker";
import { connect } from "react-redux";
import { setCartProps, reloadInventory } from "Actions";
import { getItemsWithNewPrices } from "HelperFunctions/rental_items";
import { combineDateAndTime } from "HelperFunctions/general";

class DateSelector extends React.Component {
  handleChange = e => {
    const {
      event,
      inventoryFilter,
      setCartProps,
      reloadInventory
    } = this.props;
    const { name, value } = e.target;
    let newEvent = {
      ...event,
      [name]: value
    };
    const { eventStart, eventStartTime, eventEnd, eventEndTime } = newEvent;
    const eventStartDateTime = combineDateAndTime(eventStart, eventStartTime);
    const eventEndDateTime = combineDateAndTime(eventEnd, eventEndTime);
    newEvent = Object.assign(newEvent, {
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime,
      eventStartTime: eventStartDateTime,
      eventEndTime: eventEndDateTime
    })
    setCartProps(newEvent);

    // Reload inventory with new event dates
    reloadInventory({
      ...inventoryFilter,
      eventStart: eventStartDateTime,
      eventEnd: eventEndDateTime
    });
  };

  render() {
    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime
    } = this.props;

    return (
      <div className="dateSelector">
        <label>Order Start</label>
        <DatePicker
          name="eventStart"
          value={eventStart}
          onChange={this.handleChange}
        />
        <TimePicker
          name="eventStartTime"
          value={eventStartTime}
          onChange={this.handleChange}
        />
        <label>Order End</label>
        <DatePicker
          name="eventEnd"
          value={eventEnd}
          startDate={eventStart ? eventStart : new Date()}
          onChange={this.handleChange}
        />
        <TimePicker
          name="eventEndTime"
          value={eventEndTime}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { event } = state.cart;
  const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;

  const { inventoryFilter } = state.products;
  return {
    event,
    eventStart,
    eventStartTime,
    eventEnd,
    eventEndTime,
    inventoryFilter
  };
};

export default connect(mapStateToProps, { setCartProps, reloadInventory })(
  DateSelector
);
