import {
  SET_CART_PROPS,
  CLEAR_CART_PROPS,
  SET_NEW_EVENT_CART_PROPS,
  SET_CART_TRANSACTION_PROPS,
  INIT_CART_PROPS,
  REFRESH_CART_ITEMS,
} from 'Constants/redux';
import { isSsr } from 'HelperFunctions/ssr';
import { restoreCart } from 'HelperFunctions/general';
import cloneDeep from 'lodash/cloneDeep';

let defaultStart = new Date();
let defaultEnd = new Date();
defaultStart.setHours(defaultStart.getHours(), 0, 0, 0);
defaultEnd.setDate(defaultEnd.getDate() + 1);
defaultEnd.setHours(defaultEnd.getHours() + 1, 0, 0, 0);

const cartEvent = restoreCart();

const INITIAL_CART = {
  eventStartTime: defaultStart,
  eventEndTime: defaultEnd,
  eventStart: defaultStart,
  eventEnd: defaultEnd,
  name: '',
  inventoryOnCart: [],
  customerContactPhone: '',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  deliveryType: 'none',
  deliveryAddressLocationName: '',
  deliveryAddressStreetAddress1: '',
  deliveryAddressStreetAddress2: '',
  deliveryAddressCity: '',
  deliveryAddressLocale: '',
  deliveryAddressPostalCode: '',
  preferredDeliveryWindow: '',
  preferredPickupWindow: '',
  pickupSameAsDelivery: true,
  pickupAddressLocationName: '',
  pickupAddressStreetAddress1: '',
  pickupAddressStreetAddress2: '',
  pickupAddressCity: '',
  pickupAddressLocale: '',
  pickupAddressPostalCode: '',
  editingEvent: false,
};

const INITIAL_STATE = {
  event: cartEvent || cloneDeep(INITIAL_CART),
  newEvent: cloneDeep(INITIAL_CART),
  newCustomer: {
    firstName: '',
    lastName: '',
    email: '',
  },
  loading: false,
  addingItems: false,
};

export default (state = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case SET_CART_PROPS:
      const newEvent = {
        ...state.event,
        ...action.payload,
      };
      newState = { ...state, event: newEvent };
      break;
    case CLEAR_CART_PROPS:
      newState = {
        ...INITIAL_STATE,
        event: { ...INITIAL_CART }
      };
      break;
    case SET_NEW_EVENT_CART_PROPS:
      newState = {
        ...state,
        newEvent: { ...state.newEvent, ...action.payload },
      };
      break;
    case SET_CART_TRANSACTION_PROPS:
      newState = {
        ...state,
        ...state.transaction,
        ...action.payload,
      };
      break;
    case REFRESH_CART_ITEMS:
      const products = action.products;
      const cartItems = [...state.event.items];

      cartItems.forEach((item, index) => {
        cartItems[index].product =
          products.find(
            (product) =>
              product.id === item.product?.id &&
              product.productType === item.product?.productType
          ) || item.product;
      });

      const newEventWithRefreshedItems = { ...state.event, items: cartItems };
      newState = { ...state, event: newEventWithRefreshedItems };
      break;
    case INIT_CART_PROPS:
      localStorage.removeItem('cartEvent');
      newState = {
        event: cloneDeep(INITIAL_CART),
        loading: false,
        addingItems: false,
      };
      break;
    default:
      newState = state;
      break;
  }

  if (
    newState.event.inventoryOnCart?.length !== 0 ||
    newState.event.eventStartTime !== INITIAL_CART.eventStartTime ||
    newState.event.eventEndTime !== INITIAL_CART.eventEndTime
  ) {
    if (!isSsr())
      localStorage.setItem('cartEvent', JSON.stringify(newState.event));
  } else {
    if (!isSsr()) localStorage.removeItem('cartEvent');
    newState = {
      event: {
        ...cloneDeep(INITIAL_CART),
        customerContactPhone: newState.event.customerContactPhone,
      },
      newEvent: newState.newEvent,
      newCustomer: newState.newCustomer,
      loading: false,
      addingItems: false,
    };
  }

  return newState;
};
