import isEqual from "lodash/isEqual";
export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (var i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const combineDateAndTime = (date, time) => {
  date = new Date(date);
  time = new Date(time);
  let combinedDate;
  if (date && time) {
    combinedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes()
    );
  } else if (date) {
    combinedDate = date;
  } else if (time) {
    combinedDate = time;
  }
  return combinedDate;
};

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})?(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    var areaCode = match[2] ? `(${match[2]}) ` : '';
    return [intlCode, areaCode, match[3], '-', match[4]].join('');
  } else {
    return phoneNumberString;
  }
};

export function isSupportedBrowser() {
  return !isIE();
}
export function isChrome() {
  if (typeof navigator === 'undefined') return false;
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
}

function isIE() {
  if (typeof navigator === 'undefined') return false;
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return is_ie;
}
export const toNumber = (value) => Number(value) || value;

export const restoreCart = () => {
  const cartEvent =
    typeof localStorage !== 'undefined'
      ? JSON.parse(localStorage.getItem('cartEvent') || null)
      : null;

  if (!cartEvent) {
    return null;
  }

  let start = new Date(cartEvent.eventStartTime);
  let end = new Date(cartEvent.eventEndTime);

  return {
    ...cartEvent,
    eventStart: start,
    eventStartTime: start,
    eventEnd: end,
    eventEndTime: end,
  };
};

export const debounce = (fn, delay) => {
  var timer = null;
  return function () {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

export const getHostname = function (url) {
  try {
    if (url.startsWith('http')) {
      const newUrl = new URL(url);
      return newUrl.hostname;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
  return null;
};

export const getPathname = function (url) {
  try {
    if (url.startsWith('http')) {
      const newUrl = new URL(url);
      return newUrl.pathname;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
  return url;
};

export const compareObjects = function (obj1, obj2) {
  // Make sure an object to compare is provided
  if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
    return obj1;
  }

  //
  // Variables
  //

  var diffs = {};
  var key;

  //
  // Methods
  //

  /**
   * Check if two arrays are equal
   * @param  {Array}   arr1 The first array
   * @param  {Array}   arr2 The second array
   * @return {Boolean}      If true, both arrays are equal
   */
  var arraysMatch = function (arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
      if (!isEqual(arr1[i], arr2[i])) return false;
    }

    // Otherwise, return true
    return true;
  };

  /**
   * Compare two items and push non-matches to object
   * @param  {*}      item1 The first item
   * @param  {*}      item2 The second item
   * @param  {String} key   The key in our object
   */
  var compare = function (item1, item2, key) {
    // Get the object type
    var type1 = Object.prototype.toString.call(item1);
    var type2 = Object.prototype.toString.call(item2);

    // If type2 is undefined it has been removed
    if (type2 === '[object Undefined]') {
      diffs[key] = null;
      return;
    }

    // If items are different types
    if (type1 !== type2) {
      diffs[key] = item2;
      return;
    }

    // If an object, compare recursively
    if (type1 === '[object Object]') {
      var objDiff = compareObjects(item1, item2);
      if (Object.keys(objDiff).length > 0) {
        diffs[key] = objDiff;
      }
      return;
    }

    // If an array, compare
    if (type1 === '[object Array]') {
      if (!arraysMatch(item1, item2)) {
        diffs[key] = item2;
      }
      return;
    }
    // If an date, compare
    if (type1 === '[object Date]') {
      if (item1.getTime() !== item2.getTime()) {
        diffs[key] = item2;
      }
      return;
    }

    // Else if it's a function, convert to a string and compare
    // Otherwise, just compare
    if (type1 === '[object Function]') {
      if (item1.toString() !== item2.toString()) {
        diffs[key] = item2;
      }
    } else {
      if (item1 !== item2) {
        diffs[key] = item2;
      }
    }
  };

  //
  // Compare our objects
  //

  // Loop through the first object
  for (key in obj1) {
    if (obj1.hasOwnProperty(key)) {
      compare(obj1[key], obj2[key], key);
    }
  }

  // Loop through the second object and find missing items
  for (key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (!obj1[key] && obj1[key] !== obj2[key]) {
        diffs[key] = obj2[key];
      }
    }
  }

  // Return the object of differences
  return diffs;
};

export const extractKeysFromObject = (obj, keys) => {
  if (!obj) return {};

  return keys.reduce((acc, key) => {
    if (obj[key]) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
