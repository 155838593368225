/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuIcon, Close, Cart } from 'Utils/SvgIcons';
import SmartNavLink from 'Utils/SmartNavLink';
import { connect } from 'react-redux';
import MobileNavLinkAccordion from './MobileNavLinkAccordion';

class MobileSignedOutNav extends React.Component {
  state = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  closeMenu = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const { event, storefrontShopSetting, storefrontMenuSetting, mainSetting } =
      this.props;
    const { open } = this.state;
    const usingPreScott = mainSetting.theme === 'preScott';
    let navs = [];
    storefrontMenuSetting.storefrontMenuItems.forEach((menuItem, index) => {
      const link = menuItem.menuLink;
      if (menuItem.childMenuItems.length === 0) {
        // If no child menu items
        navs.push(
          <SmartNavLink
            key={navs.length + 1}
            exact
            link={link}
            target={menuItem.openInNewTab ? '_blank' : ''}
            onClick={this.toggleMenu}
          >
            {menuItem.menuTitle}
          </SmartNavLink>
        );
      } else {
        // If child menu items present?
        usingPreScott
          ? navs.push(
              <MobileNavLinkAccordion
                toggleMenu={this.toggleMenu}
                key={navs.length + 1}
                link={link}
                menuItem={menuItem}
              />
            )
          : navs.push(
              <div onClick={this.toggleMenu} key={navs.length + 1}>
                <SmartNavLink
                  exact
                  link={link}
                  target={menuItem.openInNewTab ? '_blank' : ''}
                  onClick={this.toggleMenu}
                >
                  {menuItem.menuTitle}
                </SmartNavLink>
                {menuItem.childMenuItems.map((childMenuItem, index) => (
                  <nav key={index}>
                    <SmartNavLink
                      exact
                      link={childMenuItem.menuLink}
                      target={childMenuItem.openInNewTab ? '_blank' : ''}
                      onClick={this.toggleMenu}
                    >
                      {childMenuItem.menuTitle}
                    </SmartNavLink>
                  </nav>
                ))}
              </div>
            );
      }
    });

    const activeInventoryCount = event.inventoryOnCart?.filter((item) => item._destroy !== '1').length

    return (
      <nav className='mobileMenu'>
        <div className='topLink'>
          <NavLink onClick={this.closeMenu} className='navSignIn' to='/login'>
            SIGN IN
          </NavLink>
          <span onClick={this.toggleMenu}>
            {open ? <Close /> : <MenuIcon />}
          </span>
        </div>
        {storefrontShopSetting.shopVisible && (
          <NavLink className='topLink shoppingCart' to='/cart'>
            <Cart />
            <label>
              {activeInventoryCount > 0 && activeInventoryCount}
            </label>
          </NavLink>
        )}

        {open && (
          <div className='mobileMenuOverlay'>
            <a className='topLink' onClick={this.toggleMenu}>
              <Close />
            </a>

            {storefrontShopSetting.shopVisible && (
              <NavLink
                className='topLink shoppingCart'
                to='/cart'
                onClick={this.toggleMenu}
              >
                <Cart />
                <label>
                  {event.inventoryOnCart?.filter((item) => item._destroy !== '1').length >
                    0 &&
                    event.inventoryOnCart?.filter((item) => item._destroy !== '1').length}
                </label>
              </NavLink>
            )}
            {!usingPreScott ? (
              <nav className='noGreeting'>
                <NavLink to='/login' onClick={this.toggleMenu}>
                  Sign In
                </NavLink>
                <NavLink to='/signUp' onClick={this.toggleMenu}>
                  Create Account
                </NavLink>
                {navs}
              </nav>
            ) : (
              <nav className='noGreeting'>{navs}</nav>
            )}
          </div>
        )}
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  const { event } = state.cart;
  const {
    parsedStorefrontSettings: {
      storefrontShopSetting,
      storefrontMenuSetting,
      mainSetting,
    },
  } = state.locations;

  return { event, storefrontShopSetting, storefrontMenuSetting, mainSetting };
};

export default connect(mapStateToProps)(MobileSignedOutNav);
