import React from 'react';
import { parseQuery } from 'HelperFunctions/QueryString';
import GetRedirectHost from './AddToCart.graphql';
import { apolloClient } from '../Root';

class AddToCart extends React.PureComponent {
  validateRedirectUrl = async (url) => {
    if (typeof url !== 'string') {
      return false;
    }

    const response = await apolloClient.query({
      query: GetRedirectHost,
      variables: { url },
    });

    return !!response.data?.getRedirectHost?.hostname;
  };

  async componentDidMount() {
    const { location } = this.props;

    const { itemId, itemType, quantity, redirectUrl } = parseQuery(
      JSON.parse(JSON.stringify(location.search))
    );

    const isValidItemId = /^\d+$/.test(itemId) && parseInt(itemId) > 0;
    const isValidQuantity = /^\d+$/.test(quantity) && parseInt(quantity) >= 0;
    const isValidItemType = ['items', 'addons', 'bundles'].includes(itemType);

    const isValidRedirectUrl = await this.validateRedirectUrl(redirectUrl);
    
    if (!isValidRedirectUrl) {
      window.location.replace('/');
      return;
    }

    if (!isValidItemId || !isValidItemType || !isValidQuantity) {
      window.location.replace(`${redirectUrl}?failed=true`);
      return;
    }

    let cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    const itemEntryIndex = cartItems.findIndex(
      (cartItem) =>
        cartItem.itemId === parseInt(itemId) && cartItem.itemType === itemType
    );

    if (itemEntryIndex !== -1) {
      cartItems[itemEntryIndex].quantity += parseInt(quantity);
    } else {
      cartItems.push({
        itemId: parseInt(itemId),
        itemType,
        quantity: parseInt(quantity),
      });
    }

    localStorage.setItem('cartItems', JSON.stringify(cartItems));

    window.location.replace(redirectUrl);
  }

  render() {
    return <div>Loading...</div>;
  }
}

export default AddToCart;
