/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { setFilterParams, filterFromPropsAndState } from 'HelperFunctions/urls';
import { connect } from 'react-redux';
import axios from 'axios';
import * as actions from 'Actions';
import classnames from 'classnames';
import CartItems from './CartItems';
import CartBilling from './CartBilling';
import EventForm from './EventForm';
import EventSummary from './EventSummary';
import Modal from 'Utils/Modal';
import SignInForm from 'Components/home/SignInForm';
import SignUpForm from 'Components/home/SignUpForm';
import ConnectPaymentBox from 'Components/guest_checkout/ConnectPaymentBox';
import SignatureModal from 'Utils/SignatureModal';
import { objectToFormData } from 'HelperFunctions/formHelpers';
import { Alert, CartCheck, Burst, ArrowRight } from 'Utils/SvgIcons';
import { history as browserHistory } from 'Components/Routes';
import Validator from 'HelperFunctions/validator';
import { combineDateAndTime } from 'HelperFunctions/general';
import { parseQuery } from 'HelperFunctions/QueryString';
import { getEventDates } from './utils/getEventDates';

class Cart extends React.Component {
  constructor(props) {
    super(props);
    const { authenticated } = props;

    this.state = {
      signInOpen: false,
      signUpOpen: false,
      paymentOpen: false,
      signModalOpen: false,
      rentalSigned: false,
      paymentSuccess: false,
      customer: null,
      customerType: '',
      deliveryType: 'none',
      position: 0,
      willPayNowAndReserve: authenticated,
    };
    this.reviewStepOne = this.reviewStepOne.bind(this);
    this.reviewStepTwo = this.reviewStepTwo.bind(this);
  }
  
  componentDidMount() {
    const { event, setCartProps, convertStorefrontToV2PayloadAndReloadStore, authenticated } = this.props;
    if (
      event.inventoryOnCart ===
      0
    ) {
      browserHistory.push('/cart');
    }

    if (event.editingEvent) {
      browserHistory.push(`events/${event.token}/edit`)
      return;
    }

    //Track y-coordinate for sticky nav bar
    window.addEventListener('scroll', () => {
      this.setState({
        position: window.pageYOffset,
      });
    });

    setCartProps({ cartHasChanged: true })
    convertStorefrontToV2PayloadAndReloadStore();

    this.loadCartFromExternalRedirect();
  }

  componentDidUpdate(prevProps) {
    const { event, authenticated } = this.props;

    const customerExists = !!this.props.customer;
    const customerEmailExists = customerExists && !!this.props.customer.email;
    const customerEmailIsDifferentThanEvent = customerEmailExists && this.props.customer.email !== event.customerEmail;

    // This effect will enforce the authenticated user's email whenever available.
    if(customerEmailIsDifferentThanEvent && authenticated) {
      this.props.setCartProps({ 
        customerEmail: this.props.customer.email 
      })

      // Updating email can impact order taxes, so we need to reload the pricing
      this.props.convertStorefrontToV2PayloadAndReloadStore();
    }

    if (this.props.customer?.mainContactAddress && !event.customerInfoAutofilled) {
      const { setCartProps, customer } = this.props;

      setCartProps({
        deliveryAddressCountry: customer?.mainContactAddress?.country,
        deliveryAddressStreetAddress1: customer?.mainContactAddress?.streetAddress1,
        deliveryAddressStreetAddress2: customer?.mainContactAddress?.streetAddress1,
        deliveryAddressCity: customer?.mainContactAddress?.city,
        deliveryAddressLocale: customer?.mainContactAddress?.locale,
        deliveryAddressPostalCode: customer?.mainContactAddress?.postalCode,
        customerInfoAutofilled: true,
      });
    }
  }

  mergeExistingAndExternalCarts = (
    existingEvent,
    { products = [], addOns = [], bundles = [] } = {}
  ) => {
    existingEvent?.items?.forEach((item) => {
      const externalCartProduct = products.find(
        (p) => Number(p.id) === Number(item.productId)
      );

      if (!externalCartProduct) {
        products.push({
          id: Number(item.productId),
          quantity: item.quantity,
        });

        return;
      }

      externalCartProduct.quantity += item.quantity;
    });

    existingEvent?.rentalBundles?.forEach((item) => {
      const externalCartBundle = bundles.find(
        (b) => Number(b.id) === Number(item.bundleId)
      );

      if (!externalCartBundle) {
        bundles.push({
          id: Number(item.bundleId),
          quantity: item.quantity,
        });

        return;
      }

      externalCartBundle.quantity += item.quantity;
    });

    existingEvent?.addOns?.forEach((item) => {
      const externalCartAddOn = addOns.find(
        (a) => Number(a.id) === Number(item.addOnId)
      );

      if (!externalCartAddOn) {
        addOns.push({
          id: Number(item.addOnId),
          quantity: item.quantity,
        });

        return;
      }

      externalCartAddOn.quantity += item.quantity;
    });

    return { products, addOns, bundles };
  };

  loadCartFromExternalRedirect = () => {
    const { routerSearch, setCartProps, event, convertStorefrontToV2PayloadAndReloadStore } = this.props;

    const {
      externalRedirect,
      eventStart: queryStartDate,
      eventEnd: queryEndDate,
    } = parseQuery(routerSearch);
    
    if (!externalRedirect) return;

    const { eventStart, eventEnd } = getEventDates(this.props, {
      eventStart: queryStartDate,
      eventEnd: queryEndDate,
    });

    const cartItemsFromLocalStorage = JSON.parse(localStorage.getItem('cartItems'));

    setCartProps({
      eventStart,
      eventStartTime: eventStart,
      eventEnd,
      eventEndTime: eventEnd,
      inventoryOnCart: cartItemsFromLocalStorage || []
    });

    window.location.replace('/cart');
  };

  toggleSignUp = () => {
    this.setState({
      signUpOpen: !this.state.signUpOpen,
      signInOpen: this.state.signUpOpen,
    });
  };

  toggleSignIn = () => {
    this.setState({
      signInOpen: !this.state.signInOpen,
      signUpOpen: this.state.signInOpen,
    });
  };

  closeSignIn = () => {
    this.setState({
      signInOpen: false,
    });
  };

  closeSignUp = () => {
    this.setState({
      signUpOpen: false,
    });
  };

  handleSelectStep = (step) => {
    const { location, history, convertStorefrontToV2PayloadAndReloadStore } = this.props;
    convertStorefrontToV2PayloadAndReloadStore()
    setFilterParams(
      {
        step: step,
      },
      location,
      history
    );
  };

  handleSubmitQuote = (e) => {
    e.preventDefault();
    const component = this;
    if (component.props.authenticated) {
      component.props.newSubmit(false, (event) => {
        component.eventRedirect(event);
      });
    } else {
      component.props.newSubmitWithoutAuth(false, (event) => {
        component.eventRedirect(event);
      });
    }
  };

  handleReserveQuote = (e = null) => {
    if (e) e.preventDefault();
    const component = this;
    const {
      newCustomer: customer,
      event: { customerContactPhone },
    } = this.props;
    if (component.props.authenticated) {
      component.props.newSubmit(true, (event) => {
        component.eventRedirect(event);
      });
    } else {
      component.props.newSubmitWithoutAuth(true, (event) => {
        component.eventRedirect(event);

        const clr = event.customerRentalRelationships[0];
        let newCustomer = {
          id: clr.clientId,
          clientId: clr.clientId,
          clientType: clr.clientType,
          firstName: customer.firstName,
          lastName: customer.lastName,
          email: customer.email,
          phone: customerContactPhone,
        };

        this.setState({
          customer: newCustomer,
          customerType: clr.clientType,
        });
      });
    }
  };

  isOnlyLetters = (str) => {
    let strWithoutSpaces = str.replace(/\s/g, '');
    return /^[a-zA-Z]+$/.test(strWithoutSpaces);
  };

  handleSubmitSignature = ({
    signature,
    signerName,
    signerTitle,
    signerCompany,
  }) => {
    const {
      openLoadingSpinner,
      closeLoadingSpinner,
      newEvent,
      setErrors,
      openSuccessSnackBar,
    } = this.props;
    const rentalSignature = getSignatureFromRental(rental, 'customer');

    if (signature && this.isOnlyLetters(signerName)) {
      openLoadingSpinner('Submitting signature...');
      const data = {
        rental_signatures_attributes: [
          {
            id: rentalSignature?.id || null,
            signature: signature,
            signer_name: signerName,
            signer_title: signerTitle,
            signer_company: signerCompany,
            signature_type: 'customer',
          },
        ],
      };
      const params = objectToFormData({ rental: data });
      axios
        .patch(
          `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${newEvent.token}/submit_signature`,
          params
        )
        .then((response) => {
          closeLoadingSpinner();
          this.setState({ rentalSigned: true });
          this.toggleSignModal();
          openSuccessSnackBar('Sucessfully added signature');
        })
        .catch((error) => {
          console.log(error);
          const errors = error.response.data;
          setErrors(errors);
        });
    } else {
      setErrors('Signature cannot be blank or be only empty spaces.');
    }
  };

  makeLead = () => {
    this.props.setCartProps({
      approvalStatus: 'lead',
      deliveryType: 'none',
    });
    this.handleSelectStep(3);
  };

  renderLeftSection = (step) => {
    const {
      event,
      newEvent,
      setCartProps,
      location,
      calculateDefaultDeliveryPrice,
      getItemsWithNewPrices,
      storefrontShopSetting,
    } = this.props;
    const { deliveryType } = this.state;

    switch (step) {
      case 1:
        return (
          <CartItems
            step={step}
            setEventProperties={setCartProps}
            getItemsWithNewPrices={getItemsWithNewPrices}
            renderNextButton={this.renderNextButton}
            handleSelectStep={this.handleSelectStep}
            reviewStepOne={this.reviewStepOne}
            reviewStepTwo={this.reviewStepTwo}
          />
        );
      case 2:
        return (
          <EventForm
            canSkipDelivery={
              !storefrontShopSetting.requiredFields.includes('delivery')
            }
            deliverySetting={location.deliverySetting}
            makeLead={this.makeLead}
            setEventProperties={setCartProps}
            calculateDefaultDeliveryPrice={calculateDefaultDeliveryPrice}
            step={step}
            renderNextButton={this.renderNextButton}
            handleSelectStep={this.handleSelectStep}
            reviewStepOne={this.reviewStepOne}
            reviewStepTwo={this.reviewStepTwo}
          />
        );
      case 3:
        return (
          <EventSummary
            event={event}
            step={step}
            renderNextButton={this.renderNextButton}
            newCustomer={this.props.newCustomer}
            deliverySetting={location.deliverySetting}
            handleSelectStep={this.handleSelectStep}
            reviewStepOne={this.reviewStepOne}
            reviewStepTwo={this.reviewStepTwo}
            setEventProperties={setCartProps}
            handleReserveQuote={this.handleReserveQuote}
            changeWillPayNowAndReserve={this.changeWillPayNowAndReserve}
            autobookPossible={this.autobookPossible}
          />
        );
      case 4:
        return (
          <EventSummary
            event={event}
            step={step}
            renderNextButton={this.renderNextButton}
            newCustomer={this.props.newCustomer}
            deliverySetting={location.deliverySetting}
            handleSelectStep={this.handleSelectStep}
            reviewStepOne={this.reviewStepOne}
            reviewStepTwo={this.reviewStepTwo}
            setEventProperties={setCartProps}
            cartConfirmation={true}
          />
        );
      default:
        return (
          <CartItems
            event={event}
            setEventProperties={setCartProps}
            handleSelectStep={this.handleSelectStep}
            reviewStepOne={this.reviewStepOne}
            reviewStepTwo={this.reviewStepTwo}
          />
        );
    }
  };

  handleSignIn = (data) => {
    const component = this;
    const { loginUser } = component.props;
    loginUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false,
        });
      },
    });
  };

  handleSignUp = (data) => {
    const component = this;
    const { createUser, newEvent } = component.props;
    const { customer } = this.state;

    if (newEvent && newEvent.id && customer) {
      data = Object.assign({
        ...data,
        fromCart: true,
        clientId: customer.clientId,
      });
    }

    createUser({
      ...data,
      onSuccess: () => {
        component.setState({
          signInOpen: false,
          signUpOpen: false,
        });
      },
    });
  };

  togglePayment = (data) => {
    this.setState({
      paymentOpen: !this.state.paymentOpen,
    });
  };

  toggleSignModal = () => {
    this.setState({
      signModalOpen: !this.state.signModalOpen,
    });
  };

  handlePaymentSuccess = (transaction) => {
    this.togglePayment();
    this.setState({ paymentSuccess: true });
    this.props.openSuccessSnackBar('Payment successful.');
  };

  eventRedirect = (event) => {
    const {
      id,
      token,
      name,
      autoBooked,
      deliveryType,
      deliveryCost,
      deliveryAddressLocationName,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      pickupAddressCity,
      pickupAddressCountry,
      pickupAddressLocale,
      pickupAddressLocationName,
      pickupAddressPostalCode,
      pickupAddressStreetAddress1,
      pickupAddressStreetAddress2,
      pickupSameAsDelivery,
    } = event;
    const newObject = Object.assign(
      {
        id,
        token,
        name,
        autoBooked,
        deliveryType,
        deliveryCost,
        deliveryAddressLocationName,
        deliveryAddressStreetAddress1,
        deliveryAddressStreetAddress2,
        deliveryAddressCity,
        deliveryAddressLocale,
        deliveryAddressPostalCode,
        deliveryAddressCountry,
        pickupAddressCity,
        pickupAddressCountry,
        pickupAddressLocale,
        pickupAddressLocationName,
        pickupAddressPostalCode,
        pickupAddressStreetAddress1,
        pickupAddressStreetAddress2,
        pickupSameAsDelivery,
      },
      {
        items: event.rentalItemStandards,
        rentalBundles: event.rentalBundles,
        addOns: event.rentalAddOns,
        eventStart: new Date(event.schedule.eventStartDateTime),
        eventStartTime: new Date(event.schedule.eventStartDateTime),
        eventEnd: new Date(event.schedule.eventEndDateTime),
        eventEndTime: new Date(event.schedule.eventEndDateTime),
        schedule: { id: event.schedule.id },
      }
    );
    const { initCartProps, setCartNewEventProps } = this.props;

    initCartProps();
    setCartNewEventProps(newObject);
    if (autoBooked) {
      this.handleSelectStep(4);
    } else {
      browserHistory.push(`/cart/confirmation`);
    }
  };
  handleTrackProgress = () => {
    const { newEvent } = this.props;
    browserHistory.push(`/events/${newEvent.id}`);
  };

  changeWillPayNowAndReserve = (value) => {
    this.setState({ willPayNowAndReserve: value });
  };

  autobookPossible = () => {
    const { event } = this.props;

    return event.additionalInfo && event.additionalInfo.can_be_auto_booked
  };

  reviewStepOne() {
    const {
      authenticated,
      event,
      setErrors,
      availability,
    } = this.props;
    if (!event.name) {
      this.props.setCartProps({
        approvalStatus: 'lead',
      });
    }
    let eventForValidation = {
      ...event,
      eventStartDateTime: combineDateAndTime(
        event.eventStart,
        event.eventStartTime
      ),
      eventEndDateTime: combineDateAndTime(event.eventEnd, event.eventEndTime),
    };

    let itemError = false;
    if (event.inventoryOnCart.some((item) => item.quantity <= 0)) {
      itemError = true;
    }

    let eventValidations = {
      eventStart: {
        required: {
          value: true,
          message: 'Event start date is required.',
        },
      },
      eventStartTime: {
        required: {
          value: true,
          message: 'Event start time is required.',
        },
      },
      eventEnd: {
        required: {
          value: true,
          message: 'Event end date is required.',
        },
      },
      eventEndTime: {
        required: {
          value: true,
          message: 'Event end time is required.',
        },
      },
      name: {
        required: {
          value: true,
          message: 'Rental name is required.',
        },
      },
      customerContactPhone: {
        required: {
          value: true,
          message: 'Customer phone number is required.',
        },
      },
      ...(!authenticated && {
        customerFirstName: {
          required: {
            value: true,
            message: 'Customer first name is required.',
          },
        },
        customerLastName: {
          required: {
            value: true,
            message: 'Customer last name is required.',
          },
        },
        customerEmail: {
          required: {
            value: true,
            message: 'Customer email address is required.',
          },
          email: {
            value: true,
            message: 'Invalid customer email.',
          },
        },
      })
    };

    const eventErrors = Validator(eventValidations)(eventForValidation);
    let errors = {};
    if (Object.keys(eventErrors).length > 0) {
      errors = eventErrors;
    }
    if (event.customerContactPhone) {
      const { customerContactPhone } = event;
      const phone = { customerContactPhone: 'Invalid Phone Number' };
      if (customerContactPhone[0] === 1) {
        if (customerContactPhone.length !== 14) {
          errors = { ...errors, ...phone };
        }
      } else {
        if (customerContactPhone.length !== 12) {
          errors = { ...errors, ...phone };
        }
      }
    }
    if (event.name) {
      const { name } = event;
      const nameError = { name: 'Name must be of 5 digits' };
      if (name.length < 5) {
        errors = { ...errors, ...nameError };
      }
    }

    if (!event.additionalInfo) {
      errors = { ...errors, ...{ overbooking: 'Order is not fully loaded. Please try again.' } };
    }

    if (
      event.additionalInfo.has_overbooking
    ) {
      const overBookingError = {
        overbooking:
          'Cannot continue due to the item being unavailable at that quantity.',
      };
      errors = { ...errors, ...overBookingError };
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      if (itemError) {
        setErrors('Amount must be greater than 0.');
      } else {
        this.handleSelectStep(2);
      }
    }
  }

  reviewStepTwo() {
    const { event, setErrors } = this.props;
    let eventForValidation = {
      ...event,
      eventStartDateTime: combineDateAndTime(
        event.eventStart,
        event.eventStartTime
      ),
      eventEndDateTime: combineDateAndTime(event.eventEnd, event.eventEndTime),
      deliverySetting: this.props.location.deliverySetting,
    };
    let itemError = false;
    if (event.inventoryOnCart.some((item) => item.quantity <= 0)) {
      itemError = true;
    }
    const validations = {
      eventStart: {
        required: {
          value: true,
          message: 'Event start date is required.',
        },
      },
      eventStartTime: {
        required: {
          value: true,
          message: 'Event start time is required.',
        },
      },
      eventStartDateTime: {
        required: {
          value: true,
          message: 'Event start date is required.',
        },
        greaterThanOrEqualToDate: {
          value: new Date(),
          message: 'Start date cannot be in the past.',
        },
      },
      eventEnd: {
        required: {
          value: true,
          message: 'Event end date is required.',
        },
      },
      eventEndTime: {
        required: {
          value: true,
          message: 'Event end time is required.',
        },
      },
      eventEndDateTime: {
        required: {
          value: true,
          message: 'Event end date is required.',
        },
        greaterThanOrEqualToDate: {
          value: eventForValidation.eventStartDateTime,
          message: 'End date must be after start date.',
        },
      },
      deliveryAddressPostalCode: {
        required: {
          value: true,
          message: 'Zip Code is required.',
        },
      },
      deliveryAddressCountry: {
        required: {
          value: true,
          message: 'Country is required.',
        },
      },
      deliveryAddressLocale: {
        required: {
          value: true,
          message: `${
            event.deliveryAddressCountry === 'USA'
              ? 'State is required.'
              : event.deliveryAddressCountry === 'CAN'
              ? 'Province is required.'
              : 'State/Province is required.'
          }`,
        },
      },
      deliveryAddressStreetAddress1: {
        required: {
          value: true,
          message: 'Street Address is required.',
        },
      },
      deliveryAddressCity: {
        required: {
          value: true,
          message: 'City is required.',
        },
      },
      preferredDeliveryWindow: {
        required: {
          value: eventForValidation.deliveryType === 'ship' ? false : true,
          message: 'Preferred Drop Off Time is required.',
        },
      },
      preferredPickupWindow: {
        required: {
          value: eventForValidation.deliveryType === 'ship' ? false : true,
          message: 'Preferred Pick Up Time is required.',
        },
      },
    };
    const pickupValidation = {
      pickupAddressStreetAddress1: {
        required: {
          value: true,
          message: 'Street Address is required for pick up.',
        },
      },
      pickupAddressCity: {
        required: {
          value: true,
          message: 'City is required for pick up.',
        },
      },
      pickupAddressCountry: {
        required: {
          value: true,
          message: 'Country is required for pick up.',
        },
      },
      pickupAddressLocale: {
        required: {
          value: true,
          message: `${
            event.pickupAddressCountry === 'USA'
              ? 'State is required.'
              : event.pickupAddressCountry === 'CAN'
              ? 'Province is required.'
              : 'State/Province is required for pick up.'
          }`,
        },
      },
      pickupAddressPostalCode: {
        required: {
          value: true,
          message: 'Zip Code is required for pick up.',
        },
      },
    };

    let errors = {};
    if (
      event.deliveryType === 'default_delivery' ||
      event.deliveryType === 'ship'
    ) {
      errors = Validator(validations)(eventForValidation);
    } else if (
      event.deliveryType === 'none' ||
      event.deliveryType === 'Select Delivery Type'
    ) {
      errors = { deliveryType: 'Please Select Delivery Type' };
    }
    if (
      event.deliveryType === 'default_delivery' &&
      !event.pickupSameAsDelivery
    ) {
      const pickupErrors = Validator(pickupValidation)(eventForValidation);
      errors = Object.assign(errors, pickupErrors);
    }
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      if (itemError) {
        setErrors('Amount must be greater than 0.');
      } else {
        if (
          event.deliveryType === 'customer_pick_up' ||
          event.deliveryType === 'default_delivery'
        ) {
          this.props.setCartProps({
            approvalStatus: 'pending',
          });
        }
        this.handleSelectStep(3);
      }
    }
  }

  renderNextButton = () => {
    const {
      step,
      event,
      newEvent,
      location,
      storefrontShopSetting,
      authenticated,
      availability,
      rental,
    } = this.props;
    const { paymentSuccess, rentalSigned, willPayNowAndReserve } = this.state;

    const hasMinimumItemTotalAlert = event.additionalInfo && event.additionalInfo.minimum_inventory_value_for_order_not_met;

    const cartItemCount =
      (event.inventoryOnCart || []).filter((item) => item._destroy !== '1').length

    switch (step) {
      case 1:
        if (cartItemCount === 0) {
          return (
            <Link
              className='btn secondary'
              to='/shop'
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              Shop Now
            </Link>
          );
        } else {
          return (
            <a className='btn secondary' onClick={this.reviewStepOne}>
              Continue to Delivery
              <ArrowRight />
            </a>
          );
        }
      case 2:
        return (
          <div>
            {/* TODO: Review business rules for skipping delivery */}
            {/* TODO: Fix TD1-TD1-7309 */}
            {false && !storefrontShopSetting.requiredFields.includes('delivery') && (
              <a className='btn skip-step' onClick={this.makeLead}>
                Skip this Step
              </a>
            )}
            <a className='btn secondary' onClick={this.reviewStepTwo}>
              Continue to Review
              <ArrowRight />
            </a>
          </div>
        );
      case 3:
        if (hasMinimumItemTotalAlert) {
          return (
            <button className='btn alert' disabled>
              <Alert />
              Submit
            </button>
          );
        } else {
          if (this.autobookPossible() && willPayNowAndReserve) {
            return (
              <div>
                <a
                  className='btn'
                  id='submitButton'
                  onClick={this.handleSubmitQuote}
                >
                  Skip payment & Submit as Quote
                </a>

                {/* This button below triggers the submission of any forms with id="payment-form" rendered on the page. The EventSummary, AutobookPayment and other components implement those forms and handlers depending on the method of payment */}
                <button
                  id='reserveButton'
                  className='btn secondary'
                  form='payment-form'
                  type='submit'
                >
                  Pay & Reserve Now
                </button>
              </div>
            );
          } else {
            return (
              <a
                className='btn'
                id='submitButton'
                onClick={this.handleSubmitQuote}
              >
                Submit
              </a>
            );
          }
        }
      case 4:
        let btns = [];
        if (!authenticated) {
          btns.push(
            <a
              className='btn secondary'
              onClick={this.toggleSignUp}
              key='signup-btn'
            >
              Sign Up To Track Order
            </a>
          );
        } else {
          btns.push(
            <a
              className='btn secondary'
              onClick={this.handleTrackProgress}
              key='track-btn'
            >
              Track Progress
            </a>
          );
        }

        if (rental && rental.signatureRequired && !rental.hasSignature) {
          btns.push(
            <a className='btn secondary' onClick={this.toggleSignModal}>
              Signature Required
            </a>
          );
        } else {
          if (
            !(
              rental &&
              Number(rental.paymentBalance) >= Number(rental.overallTotal)
            ) &&
            newEvent &&
            newEvent.autoBooked &&
            !paymentSuccess
          ) {
            btns.push(
              <a
                className='btn secondary'
                onClick={this.togglePayment}
                key='payment-btn'
              >
                Make A Payment
              </a>
            );
          }
        }

        return btns;
      default:
        return (
          <a
            className='btn secondary'
            onClick={() => this.handleSelectStep(step + 1)}
          >
            Continue
          </a>
        );
    }
  };

  render() {
    const { step, rental, location, errors, storefrontShopSetting } =
      this.props;
    const {
      signInOpen,
      signUpOpen,
      paymentOpen,
      signModalOpen,
      customer,
      customerType,
      position,
    } = this.state;
    const { storefrontSetting } = location;

    let stickyPosition;
    switch (storefrontSetting.theme) {
      case 'harper':
      case 'harperDark':
      case 'chic':
        stickyPosition = 255;
        break;
      case 'emmit':
      case 'emmitDark':
      case 'fun':
        stickyPosition = 180;
        break;
      case 'catalina':
      case 'catalinaDark':
        stickyPosition = 230;
        break;
      case 'wisteria':
      case 'wisteriaDark':
        stickyPosition = 85;
        break;
      case 'magnolia':
      case 'magnoliaDark':
        stickyPosition = 120;
        break;
      case 'preScott':
        stickyPosition = 205;
        break;
      case 'elaina':
        stickyPosition = 150;
        break;
    }

    return (
      <div className='cart'>
        <div className='content'>
          <div className='headerWrapper'>
            <section
              className={classnames({
                progress: true,
                sticky:
                  position > stickyPosition ||
                  (position > 120 && window.innerWidth < 769),
                complete: step === 4,
              })}
            >
              <ul>
                <li
                  className={classnames({
                    active: step === 1,
                    complete: step > 1,
                  })}
                >
                  <a
                    onClick={() =>
                      step > 1 && step < 4 && this.handleSelectStep(1)
                    }
                  >
                    {step > 1 ? <CartCheck /> : <div>1</div>}
                    <label>Order</label>
                  </a>
                </li>
                <li
                  className={classnames({
                    active: step === 2,
                    complete: step > 2,
                  })}
                >
                  <a
                    onClick={() =>
                      step > 2 && step < 4 && this.handleSelectStep(2)
                    }
                  >
                    <text onClick={this.reviewStepOne}>
                      {step > 2 ? <CartCheck /> : <div>2</div>}
                    </text>
                    <a onClick={this.reviewStepOne}>Delivery</a>
                  </a>
                </li>
                <li
                  className={classnames({
                    active: step === 3,
                    complete: step > 3,
                  })}
                >
                  <a
                    onClick={() =>
                      step > 3 && step < 4 && this.handleSelectStep(3)
                    }
                  >
                    <text onClick={this.reviewStepTwo}>
                      {step > 3 ? <CartCheck /> : <div>3</div>}
                    </text>
                    <a onClick={this.reviewStepTwo}>Review & Submit</a>
                  </a>
                </li>
              </ul>
              <div className='next-buttons'>{this.renderNextButton()}</div>
            </section>
          </div>
          {this.renderLeftSection(step)}
          <CartBilling step={step} />
        </div>
        <Modal
          className='signInModal'
          title='Sign In'
          open={signInOpen}
          toggle={this.closeSignIn}
        >
          <div>
            <div className='icon'>
              <Burst />
            </div>
            <div className='details'>
              <p>
                Please sign in/up to submit,
                <br />
                track and update your events.
              </p>
              <SignInForm errors={errors} onSubmit={this.handleSignIn} />
              <a onClick={this.toggleSignUp}>or Sign Up for FREE</a>
            </div>
          </div>
        </Modal>
        <Modal
          className='signInModal'
          title='Sign Up'
          open={signUpOpen}
          toggle={this.closeSignUp}
        >
          <div>
            <div className='icon'>
              <Burst />
            </div>
            <div className='details'>
              <SignUpForm
                errors={errors}
                onSubmit={this.handleSignUp}
                customer={customer}
              />
              <a onClick={this.toggleSignIn}>or Sign In</a>
            </div>
          </div>
        </Modal>
        <Modal
          title='Make a Payment'
          className='large paymentModal'
          toggle={this.togglePayment}
          open={paymentOpen}
        >
          <ConnectPaymentBox
            onPaymentSuccess={this.handlePaymentSuccess}
            rentalId={rental.token}
            rental={rental}
            location={location}
            customer={customer}
            customerType={customerType}
          />
        </Modal>

        <SignatureModal
          open={signModalOpen}
          toggle={this.toggleSignModal}
          rental={rental}
          onSubmitSignature={this.handleSubmitSignature}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const step = newFilter.step ? +newFilter.step : 1;
  const { authenticated, errors } = state.auth;
  const { event, newEvent, newCustomer } = state.cart;
  const { rental } = state.rental;
  const { customer } = state.customer;
  const { location, parsedStorefrontSettings } = state.locations;
  const { storefrontShopSetting } = parsedStorefrontSettings;
  const { availability } = state;

  return {
    location,
    customer,
    event,
    newEvent,
    newCustomer,
    rental,
    step,
    authenticated,
    storefrontShopSetting,
    errors,
    availability,
    routerSearch: ownProps.location.search,
  };
};

export default withRouter(connect(mapStateToProps, actions)(Cart));
