import React from "react";
import { NavLink } from "react-router-dom";
import { Cart } from "Utils/SvgIcons";
import { connect } from "react-redux";

const SignedOutNav = props => {
  const inventoryOnCart = props.event.inventoryOnCart;
  const activeInventoryOnCart = inventoryOnCart ? inventoryOnCart.filter((item) => item._destroy !== '1') : [];
  const numberOfItemsOnCart = activeInventoryOnCart.reduce((n, { quantity }) => n + Number(quantity), 0);

  return (
    <nav className="mainMenu">
      <NavLink to="/login">Sign In</NavLink>
      <NavLink to="/signUp">Create Account</NavLink>
      {props.storefrontShopSetting.shopVisible && 
        props.location.sfSubscriptionTier != "basic" && (
          <NavLink className="icon shoppingCart" to="/cart">
            <Cart />
            <label>
                {
                  numberOfItemsOnCart > 0 ? numberOfItemsOnCart : ''  
                }
            </label>
          </NavLink>
      )}
    </nav>
  )
};

const mapStateToProps = state => {
  const { event } = state.cart;
  const { location } = state.locations;
  const {
    parsedStorefrontSettings: { storefrontShopSetting }
  } = state.locations;

  return { event, storefrontShopSetting, location };
};

export default connect(mapStateToProps)(SignedOutNav);
