import { getAuthToken } from 'Api';
import {
  CLEAR_CART_PROPS,
  FETCH_RENTAL_SUCCESS,
  INIT_CART_PROPS,
  SET_CART_NEW_CUSTOMER_PROPS,
  SET_CART_PROPS,
  SET_CART_TRANSACTION_PROPS,
  SET_NEW_EVENT_CART_PROPS,
} from 'Constants/redux';
import axios from 'axios';
import {
  closeLoadingSpinner,
  openLoadingSpinner,
} from './LoadingSpinnerActions';
import { setErrors } from './SnackbarActions';

export const setCartProps = (newProps) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    dispatch({
      type: SET_CART_PROPS,
      payload: {
        ...newProps,
        location,
      },
    });
  };
};

export const convertStorefrontToV2PayloadAndReloadStore = () => {
  return (dispatch, getState) => {
    const { event } = getState().cart;

    dispatch(setCartProps({ cartHasChanged: true }))

    // Storefront works with a Simplified Storefront Payload with only the few information that can be controlled by the user
    // This sends the Simplified Storefront Payload to the backend to be converted to the Complete V2 Payload with the full pricing calculations

    const duppedEvent = JSON.parse(JSON.stringify(event));
    duppedEvent.location_id = getState().locations.location.id;
    delete duppedEvent.billing_lines;
    delete duppedEvent.translated_payload;
    delete duppedEvent.location;
    delete duppedEvent.billingLines;
    delete duppedEvent.translatedPayload;
    delete duppedEvent.additional_info;
    delete duppedEvent.additionalInfo;
    duppedEvent.inventoryOnCart.forEach((item) => {
      delete item.product;
      delete item.parentInventory;
    })

    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/translate_payload_to_v2`, { params: duppedEvent })
      .then(response => {
        dispatch(setCartProps({
          billingLines: response.data.billing_lines,
          additionalInfo: response.data.additional_info,
          cartHasChanged: false,
        }))
      })
      .catch(error => {
        if (error?.response?.data) {
          dispatch(setErrors(error.response.data));
          return
        }

        dispatch(setErrors(error));
      });
  };
};

export const clearCartProps = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CLEAR_CART_PROPS,
      payload: {},
    });
  };
};

export const setCartNewEventProps = (newProps) => {
  return {
    type: SET_NEW_EVENT_CART_PROPS,
    payload: newProps,
  };
};

export const setCartNewCustomerProps = (newProps) => {
  return {
    type: SET_CART_NEW_CUSTOMER_PROPS,
    payload: newProps,
  };
};

export const setCartTransactionProps = (newProps) => {
  return {
    type: SET_CART_TRANSACTION_PROPS,
    payload: newProps,
  };
};

export const initCartProps = () => {
  return {
    type: INIT_CART_PROPS,
  };
};

const build_rails_object = (state, includePaymentInformation = true) => {
  const { event } = state.cart;
  const { billing_note, new_bank, new_card, send_receipt, transaction } =
    state.cart;

  const eventCopy = { ...event };

  delete eventCopy.cartHasChanged;
  delete eventCopy.translatedPayload;
  delete eventCopy.billingLines;
  delete eventCopy.location;
  eventCopy.inventoryOnCart.forEach((item) => {
    delete item.product;
  })

  return {
    ...eventCopy,
    billing_note: billing_note,
    send_receipt: send_receipt,
    ...(includePaymentInformation && {
      new_bank: new_bank,
      new_card: new_card,
      transaction: transaction,
    })
  };
};

export const newSubmit = (autoBookNeeded, onSuccess) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const data = build_rails_object(getState(), autoBookNeeded);

    dispatch(openLoadingSpinner('Submitting Order...'));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/rentals/create_with_auth?location_id=' +
          location.id,
        {
          location_id: location.id,
          auto_book: true,
          rental: data,
          location: data.location,
          ...data,
        },
        {
          headers: getAuthToken(),
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        const rental = response.data.rental;
        dispatch({
          type: FETCH_RENTAL_SUCCESS,
          payload: { rental },
        });
        onSuccess(rental);
      })
      .catch((error) => {
        dispatch(setErrors(error?.response?.data?.errors));
        dispatch(closeLoadingSpinner());
        console.log(error);
        console.log(error?.response?.data?.errors);
      });
  };
};

export const newSubmitWithoutAuth = (autoBookNeeded, onSuccess) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const { newCustomer } = getState().cart;
    const data = build_rails_object(getState(), autoBookNeeded);

    dispatch(openLoadingSpinner('Submitting Order...'));
    axios
      .post(
        process.env.REACT_APP_API_DOMAIN +
          '/api/portal/rentals/create_without_auth?location_id=' +
          location.id,
        {
          ...data,
          location_id: location.id,
          auto_book: autoBookNeeded,
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        const rental = response.data.rental;
        const customer = response.data.customer;
        dispatch({
          type: FETCH_RENTAL_SUCCESS,
          payload: { rental, customer },
        });
        onSuccess(rental);
      })
      .catch((error) => {
        dispatch(setErrors(error?.response?.data?.errors));
        dispatch(closeLoadingSpinner());
        console.log(error);
        console.log(error?.response?.data?.errors);
      });
  };
};