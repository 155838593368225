import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import NumberInput from 'Utils/NumberInput';
import { Items, Info, Alert } from 'Utils/SvgIcons';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import {
  showSelectedPrice,
  getSelectedFlatPrice,
} from 'HelperFunctions/rental_items';
import Imgix from 'react-imgix';
import classnames from 'classnames';
import OverbookingTooltip from './OverbookingTooltip';
import {
  getNumberAvailable,
  checkInventoryObjectAvailabilityLoading,
} from 'HelperFunctions/availability';
import SelectDatesModal from './SelectDatesModal';
import converter from "json-style-converter/es5";
import { translateProductTierPrice } from 'HelperFunctions/rental_items';
import { extractKeysFromObject } from 'HelperFunctions/general';
import { updatedInventoryOnCartAfterDefiningNewQuantity } from 'HelperFunctions/inventory';

class AvailableItemCard extends Component {
  constructor(props) {
    super(props);

    const {event, product} = props;

    const quantityOnCart = event?.inventoryOnCart?.find(item => {
      return item.itemType === product.productType &&
      item.itemId === product.id &&
      item._destroy != '1' &&
      !item.parentInventory?.id
      })?.quantity || 0

    this.state = {
      quantity: quantityOnCart  || 0,
      submitted: false,
      open: false,
      selectDatesModalOpen: false,
    };
  }

  handleQuantityChange = (event) => {
    this.setState({
      quantity: toNumber(event.target.value),
      submitted: false,
    });
  };

  handleAddInventoryToQuote = () => {
    const { setCartProps, setErrors, event, product } = this.props;
    const { inventoryOnCart } = event;
    const { quantity } = this.state;

    if (quantity > 0) {
      const newInventoryOnCart = updatedInventoryOnCartAfterDefiningNewQuantity(inventoryOnCart, product, quantity, !event.editingEvent)
  
      setCartProps({ inventoryOnCart: newInventoryOnCart });
      this.setState({ submitted: true });
      localStorage.setItem('cartItems', JSON.stringify(inventoryOnCart));
    } else {
      setErrors('Amount must be greater than zero.');
    }
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderPricing = (product) => {
    if (!product.defaultPeriod) return <></>;

    if (product.defaultPeriod.includes("standard_flat_price")) {
      const selectedFlatPrice = getSelectedFlatPrice(product);
      if (selectedFlatPrice) {
        return (
          <label>
            <CurrencyLabel value={selectedFlatPrice.amount} />/ Flat:{' '}
            {selectedFlatPrice.name}
          </label>
        );
      }
    } else {
      const defaultPeriod = converter.snakeToCamelCase(product.defaultPeriod)
      if (product[defaultPeriod]) {
        return (
          <label>
            <CurrencyLabel value={product[defaultPeriod]} />/{' '}
            {translateProductTierPrice(defaultPeriod)}
          </label>
        );
      }
    }

    return <label>Price On Request</label>;
  };

  userHasNotPickedStartAndEndDateAndTimes = () => {
    const {
      event: { eventStart, eventStartTime, eventEnd, eventEndTime },
    } = this.props;

    return !eventStart || !eventStartTime || !eventEnd || !eventEndTime;
  };

  closeSelectDatesModal = () => {
    this.setState({ selectDatesModalOpen: false });
  };

  render() {
    const { product, storefrontShopSetting, availability } = this.props;
    const { quantity, submitted } = this.state;

    const isAvailabilityLoading = checkInventoryObjectAvailabilityLoading(
      availability,
      product.id,
      product.productType
    );

    // TODO: Memoize it so it doesn't have to run every time the input value changes
    const numberAvailable = getNumberAvailable(
      availability,
      product.id,
      product.productType
    );

    const overbookingDisallowed =
      quantity > numberAvailable && !product.allowOverbooking;

    return (
      <div className='item'>
        <Link
          to={`/shop/${product.productType}/${product.token}/${product.slug}`}
        >
          <figure>
            {product.pictures[0] ? (
              <Imgix
                src={product.pictures[0].imgixUrl}
                alt={product.name}
                height={288}
                width={510}
              />
            ) : (
              <Items />
            )}
          </figure>
        </Link>
        {storefrontShopSetting.shopShowItemPricing &&
          product.showPriceStorefront &&
          this.renderPricing(product)}
        <strong>{product.name}</strong>
        <div>
          <NumberInput
            disabled={isAvailabilityLoading}
            type='text'
            placeholder='Qty'
            value={quantity}
            onChange={this.handleQuantityChange}
          />
          {quantity > numberAvailable && (
            <div
              className={classnames({
                info: true,
                overbooking: overbookingDisallowed,
              })}
              data-tip
              data-for={product.allowOverbooking ? 'info' : 'overbooking'}
              data-event='click'
              data-event-off='mouseleave'
            >
              {product.allowOverbooking ? <Info /> : <Alert />}
            </div>
          )}
          {quantity > numberAvailable && (
            <OverbookingTooltip
              allowOverbooking={product.allowOverbooking}
              numberAvailable={numberAvailable}
            />
          )}
          {submitted ? (
            <Link className='btn' to='/cart'>
              Added
            </Link>
          ) : (
            <button
              className={classnames({
                btn: true,
              })}
              onClick={this.handleAddInventoryToQuote}
              disabled={overbookingDisallowed}
            >
              Add
            </button>
          )}
        </div>
        <SelectDatesModal
          onClick={this.closeSelectDatesModal}
          isOpen={this.state.selectDatesModalOpen}
          onToggle={this.closeSelectDatesModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    parsedStorefrontSettings: { storefrontShopSetting },
    location,
  } = state.locations;
  const { event } = state.cart;
  const { errors } = state.dashboard;
  const { availability } = state;

  return { storefrontShopSetting, event, errors, location, availability };
};

export default withRouter(connect(mapStateToProps, actions)(AvailableItemCard));
