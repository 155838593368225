import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import moment from 'moment';

export const INVENTORY_TYPE_TRANSLATIONS = {
  items: 'RentalItem',
  bundles: 'RentalBundle',
  addons: 'RentalAddOn',
}
export const INVENTORY_PARENT_KEY_BY_TYPE = {
  items: 'product_id',
  bundles: 'bundle_id',
  addons: 'add_on_id',
}

const TIME_BASED_PRICE_LABELS = {
  hourly: { displayName: 'Hour', priceName: 'hourlyPrice' },
  half_day: { displayName: 'Half-Day', priceName: 'halfDayPrice' },
  daily: { displayName: 'Day', priceName: 'dailyPrice' },
  weekly: { displayName: 'Week', priceName: 'weeklyPrice' },
  monthly: { displayName: 'Month', priceName: 'monthlyPrice' },
};

const periods = {
  hourlyPrice: 'hourly_price',
  halfDayPrice: 'half_day_price',
  dailyPrice: 'daily_price',
  weeklyPrice: 'weekly_price',
  monthlyPrice: 'monthly_price',
};

const tiers = ['hourly', 'halfDay', 'daily', 'weekly', 'monthly'];

const hours = {
  hourly: 1,
  halfDay: 12,
  daily: 13,
  weekly: 168,
};

const productTierPriceHash = {
  hourly: 'hourlyPrice',
  halfDay: 'halfDayPrice',
  half_day: 'halfDayPrice',
  daily: 'dailyPrice',
  weekly: 'weeklyPrice',
  monthly: 'monthlyPrice',
};

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export const translateProductTierPrice = (tier) => {
  switch (tier) {
    case 'hourlyPrice':
      return 'hourly';
    case 'halfDayPrice':
      return 'halfDay';
    case 'dailyPrice':
      return 'daily';
    case 'weeklyPrice':
      return 'weekly';
    case 'monthlyPrice':
      return 'monthly';
    default:
      return null;
  }
};

const calculateHoursForEachMonth = (monthsBeingRented) => {
  const year = new Date().getFullYear();
  let hoursTotal = 0;
  monthsBeingRented.map((month) => {
    const days = new Date(year, month + 1, 0).getDate();
    const hours = days * 24;
    hoursTotal += hours;
  });

  return hoursTotal;
};

const getCorrectUsablePrice = (
  priceUnit,
  usablePrices,
  product,
  usablePrice
) => {
  let currentTierIndex = tiers.indexOf(priceUnit);
  let nextAvailableTier;

  while (currentTierIndex >= 0 && !nextAvailableTier) {
    if (product[productTierPriceHash[tiers[currentTierIndex]]]) {
      nextAvailableTier = usablePrices.find((price) => {
        return price.unit === productTierPriceHash[tiers[currentTierIndex]];
      });
    }

    currentTierIndex -= 1;
  }

  return nextAvailableTier || usablePrice;
};

const getPriceUnit = (hours, startDateTime = null, endDateTime = null) => {
  let weeklyHours = 720;
  let isWeekly;
  let startDateMonth;
  let endDateMonth;
  if (endDateTime && startDateTime) {
    const startMonth = startDateTime.getMonth();
    let endMonth = endDateTime.getMonth();

    if (startMonth !== endMonth) {
      if (endDateTime.getYear() > startDateTime.getYear()) {
        const differenceInYears =
          endDateTime.getYear() - startDateTime.getYear();
        endMonth += differenceInYears * 12;
      }

      const monthsRange = range(startMonth, endMonth);
      const monthsBeingRented = monthsRange.slice(0, -1);
      weeklyHours = calculateHoursForEachMonth(monthsBeingRented);
      isWeekly = hours < weeklyHours;
    } else {
      isWeekly = hours <= weeklyHours;
    }
  }

  if (hours < 12) {
    return 'hourly';
  } else if (hours < 24) {
    return 'halfDay';
  } else if (hours < 168) {
    return 'daily';
  } else if (isWeekly) {
    return 'weekly';
  } else {
    return 'monthly';
  }
};

const calculateRemainingTime = (currentTier, timeDiffHours) => {
  let remainingTime;
  switch (currentTier) {
    case 'halfDay':
      remainingTime = timeDiffHours % 12;
      if (remainingTime < 0) remainingTime = 0;
      return remainingTime;
    case 'daily':
      remainingTime = timeDiffHours % 24;
      if (remainingTime < 0) remainingTime = 0;
      return remainingTime;
    case 'weekly':
      remainingTime = timeDiffHours % 168;
      if (remainingTime < 0) remainingTime = 0;
      return remainingTime;
    case 'monthly':
      remainingTime = timeDiffHours % 720;
      if (remainingTime < 0) remainingTime = 0;
      return remainingTime;
    default:
      return 0;
  }
};

const calculatePricingWithSmartPricing = (
  smartPricing,
  remainder,
  quantity
) => {
  const { tier, rate } = smartPricing;
  remainder = calculateDuration(tier, remainder);

  if (remainder > 1) {
    remainder = Math.ceil(remainder);
  }

  remainder = Math.floor(remainder);
  return getPrice(remainder, rate, quantity);
};

const getAlternativePricingDetails = (
  usablePrice,
  timeDiffhours,
  startDateTime,
  endDateTime
) => {
  const currentTierIndex = tiers.indexOf(
    translateProductTierPrice(usablePrice.unit)
  );

  const furthestTier = productTierPriceHash[tiers[currentTierIndex - 2]];
  const closestTier = productTierPriceHash[tiers[currentTierIndex - 1]];
  const translatedFurthestTier = translateProductTierPrice(furthestTier);
  const translatedClosestTier = translateProductTierPrice(closestTier);

  const minHours = {
    closest: hours[translatedClosestTier] || 0,
    furthest: hours[translatedFurthestTier] || 0,
  };

  const shouldRound = checkRoundingRules(
    startDateTime,
    endDateTime,
    tiers[currentTierIndex]
  );

  const remainder = shouldRound
    ? calculateRemainingTime(tiers[currentTierIndex], timeDiffhours)
    : 0;

  const remainderTier = getPriceUnit(remainder, startDateTime, endDateTime);

  return {
    minHours,
    remainder,
    remainderTier,
  };
};

const calculateAdditionalSmartPricing = (
  smartPricing,
  additionalSmartPricingRate,
  remainder,
  quantity,
  product,
  usablePrice,
  remainderTier
) => {
  if (smartPricing) {
    return calculatePricingWithSmartPricing(smartPricing, remainder, quantity);
  } else if (remainder > 0) {
    return roundPricing(
      remainder,
      product,
      translateProductTierPrice(usablePrice.unit)
    );
  }

  return additionalSmartPricingRate;
};

const smartPricingAvailableWithinTierRange = (
  currentTier,
  remainderTier,
  product,
  remainder,
  minHours,
  smartPricingActive
) => {
  if (!smartPricingActive) return null;
  // currentTier is synonymous with primary tier
  // again, for clarification, the primary tier is what will be displayed on
  // pricing displays.

  // No smart pricing can exist for hourly since it's the smallest tier.
  if (currentTier === 'hourly' || remainder === 0) return null;

  const remainderPriceTier = productTierPriceHash[remainderTier];

  const currentIndex = tiers.indexOf(currentTier);

  const closestSmartPriceIndex = currentIndex - 1;
  const closestSmartTier = productTierPriceHash[tiers[closestSmartPriceIndex]];
  const closestSmartPrice = product[closestSmartTier];
  const closestMinHours = minHours.closest;

  // Closest Smart Price
  if (
    closestMinHours &&
    closestSmartPrice &&
    closestSmartTier === remainderPriceTier &&
    Math.floor(remainder) >= closestMinHours
  ) {
    return {
      tier: productTierPriceHash[tiers[closestSmartPriceIndex]],
      rate: closestSmartPrice,
    };
  }

  const furthestSmartPriceIndex = currentIndex - 2;
  const furthestSmartTier =
    productTierPriceHash[tiers[furthestSmartPriceIndex]];
  const furthestSmartPrice = product[furthestSmartTier];
  const furthestMinHours = minHours.furthest;

  // Furthest Smart Price
  if (furthestMinHours && furthestSmartPrice) {
    return {
      tier: productTierPriceHash[tiers[furthestSmartPriceIndex]],
      rate: furthestSmartPrice,
    };
  }

  // If neither, allow closest to supersede over primary tier rate
  if (
    furthestMinHours &&
    closestSmartPrice &&
    Math.floor(remainder) >= furthestMinHours
  ) {
    return {
      tier: productTierPriceHash[tiers[closestSmartPriceIndex]],
      rate: closestSmartPrice,
    };
  }

  return null;
};

const roundDurationIfNeeded = (
  usablePrice,
  remainder,
  monthsBeingRented,
  duration,
  remainderTier = null
) => {
  remainder = calculateDuration(usablePrice.unit, remainder);
  duration = monthsBeingRented.length;
  const dontRoundTiers = ['halfDay', 'hourly'];

  // 0.01 is +1 day remainder
  const shouldRoundUp =
    remainder > 0.01 && !dontRoundTiers.includes(remainderTier);
  if (shouldRoundUp) duration += 1;

  return duration;
};

const roundPricing = (remainder, product, currentTier) => {
  const currentTierIndex = tiers.indexOf(currentTier);

  const furthestTier = productTierPriceHash[tiers[currentTierIndex - 2]];
  const closestTier = productTierPriceHash[tiers[currentTierIndex - 1]];

  const translatedFurthestTier = translateProductTierPrice(furthestTier);
  const translatedClosestTier = translateProductTierPrice(closestTier);

  if (
    furthestTier &&
    remainder < hours[translatedFurthestTier] &&
    product[furthestTier]
  ) {
    return getPrice(1, product[furthestTier], product.quantity);
  } else if (closestTier && product[closestTier]) {
    return getPrice(1, product[closestTier], product.quantity);
  } else {
    return getPrice(
      1,
      product[productTierPriceHash[currentTier]],
      product.quantity
    );
  }

  return 0;
};

const calculateSmartPricing = (
  smartPricing,
  quantity,
  remainder,
  additionalSmartPricingRate,
  product,
  usablePrice,
  duration
) => {
  additionalSmartPricingRate = calculateAdditionalSmartPricing(
    smartPricing,
    additionalSmartPricingRate,
    remainder,
    quantity,
    product,
    usablePrice
  );

  return calculateExistingPrice(
    product[usablePrice.unit],
    Math.floor(duration),
    quantity,
    usablePrice,
    additionalSmartPricingRate
  );
};

const calculateDuration = (unit, timeDiffhours) => {
  switch (unit) {
    case 'hourlyPrice':
      return timeDiffhours;
    case 'halfDayPrice':
      return timeDiffhours / 12;
    case 'dailyPrice':
      return timeDiffhours / 24;
    case 'weeklyPrice':
      return timeDiffhours / 24 / 7;
    case 'monthlyPrice':
      return timeDiffhours / 24 / 30;
  }
};

const calculateAlternativePrice = (data) => {
  let {
    usablePrice,
    quantity,
    additionalSmartPricingRate,
    priceUnit,
    usablePrices,
    product,
    timeDiffhours,
    startDateTime,
    endDateTime,
    smartPricingActive,
  } = data;

  if (!additionalSmartPricingRate) additionalSmartPricingRate = 0;

  usablePrice = getCorrectUsablePrice(
    priceUnit,
    usablePrices,
    product,
    usablePrice
  );

  let { minHours, remainder, remainderTier } = getAlternativePricingDetails(
    usablePrice,
    timeDiffhours,
    startDateTime,
    endDateTime
  );

  const smartPricing = smartPricingAvailableWithinTierRange(
    translateProductTierPrice(usablePrice.unit),
    remainderTier,
    product,
    remainder,
    minHours,
    smartPricingActive
  );

  additionalSmartPricingRate = smartPricingActive
    ? calculateAdditionalSmartPricing(
        smartPricing,
        additionalSmartPricingRate,
        remainder,
        quantity,
        product,
        usablePrice,
        remainderTier
      )
    : 0;

  const duration =
    timeDiffhours > 0 ? calculateDuration(usablePrice.unit, timeDiffhours) : 1;

  const total =
    getPrice(
      smartPricingActive ? Math.floor(duration) : Math.round(duration),
      usablePrice.price,
      quantity
    ) + additionalSmartPricingRate;

  return {
    total,
    rate: usablePrice.price,
    duration,
    period: periods[usablePrice.unit],
  };
};

const calculateExistingPrice = (
  rate,
  duration,
  quantity,
  usablePrice,
  additionalSmartPricingRate = 0
) => {
  const total =
    getPrice(Math.ceil(duration), rate, quantity) + additionalSmartPricingRate;

  return {
    total,
    duration,
    rate: rate,
    period: periods[usablePrice.unit],
  };
};

const getDaysFromUnit = (unit, startDateTime, endDateTime) => {
  const startMonth = startDateTime.getMonth();
  const monthsBeingRented = [startMonth];
  const hoursInMonthsBeingRented =
    calculateHoursForEachMonth(monthsBeingRented);
  const daysInMonthsBeingRented = hoursInMonthsBeingRented / 24;

  switch (unit) {
    case 'daily':
      return 1;
    case 'weekly':
      return 7;
    case 'monthly':
      return daysInMonthsBeingRented;
    default:
      return 0;
  }
};

const checkRoundingRules = (startDateTime, endDateTime, unit) => {
  let durationEnd;

  if (unit === 'halfDay') {
    durationEnd = startDateTime.setHours(startDateTime.getHours() + 12);
  } else if (unit === 'daily') {
    durationEnd = startDateTime.setHours(startDateTime.getHours() + 24);
  } else {
    durationEnd = new Date(
      startDateTime.getFullYear(),
      startDateTime.getMonth(),
      startDateTime.getDate() +
        getDaysFromUnit(unit, startDateTime, endDateTime)
    );
  }

  return durationEnd < endDateTime;
};

const productHasExistingRate = (product, usablePrice) => {
  return product[usablePrice] && Number(product[usablePrice]) !== 0;
};

const getPrice = (duration, rate, quantity) => {
  if (duration <= 0) {
    duration = 1;
  }

  return duration * rate * quantity;
};

export const getProductType = (item) => {
  return item.product ? 'items' : item.bundle ? 'bundles' : 'add_ons';
};

export const hasSelectedFlatPrice = (product, productType) => {
  const selectedPricing = product.period || product.defaultPricing;
  return (
    ((productType === 'bundles' && product.priceLocked) ||
      productType === 'items') &&
    selectedPricing &&
    selectedPricing.includes('standard_flat_price')
  );
};

export const getSelectedFlatPrice = (product) => {
  const selectedPricing = product.period || product.defaultPricing;
  return product.flatPrices.find((fp) => selectedPricing.includes(fp.name));
};

export const getDepartmentPricesAndTotal = (stateObj, rentalItem) => {
  const { orderDurations, eventStartDateTime, eventEndDateTime } = stateObj;

  // configure moment and ask for the humanized distance of time between event start and end
  configureMomentRelativeTime();
  let durationToBreakpoint = '';
  const forBundle = rentalItem.hasOwnProperty('bundleId');
  if (forBundle) {
    rentalItem.departmentIgnoreWeekends =
      rentalItem.departmentIgnoreWeekends ||
      rentalItem.bundle?.departmentIgnoreWeekends;
  }
  if (
    rentalItem.departmentIgnoreWeekends &&
    ((forBundle && rentalItem.priceLocked) || !forBundle)
  ) {
    const { weekDays, totalWeekends } = calculateDays(
      eventStartDateTime,
      eventEndDateTime
    );
    const startingDuration = weekDays[0];
    let totalDuration = weekDays.slice(1).reduce((prev, curr) => {
      return curr.diff(prev) + prev;
    }, startingDuration);
    totalDuration = totalDuration - startingDuration;
    if (totalWeekends > 0) {
      totalDuration = totalDuration - totalWeekends * (24 * 60 * 60 * 1000);
    }
    totalDuration = moment.duration(totalDuration);
    durationToBreakpoint = totalDuration.humanize();
    durationToBreakpoint = orderDurations.find(
      (duration) => duration.breakpoint === durationToBreakpoint
    )
      ? durationToBreakpoint
      : '12_hours';
  } else {
    const momentStart = moment(eventStartDateTime);
    const momentEnd = moment(eventEndDateTime);
    durationToBreakpoint = moment
      .duration(momentStart.diff(momentEnd))
      .humanize();
  }
  // find order duration with matching breakpoint
  // TODO: need to fallback on 12 months if > 1 year
  const targetDuration = orderDurations.find(
    (duration) => duration.breakpoint === durationToBreakpoint
  );

  // TODO: maths
  const primaryRate =
    +rentalItem[TIME_BASED_PRICE_LABELS[targetDuration.rate].priceName];

  let rateCombinationTotal = targetDuration.multiplier * primaryRate;

  let secondaryRate;
  if (targetDuration.secondary_rate) {
    secondaryRate =
      +rentalItem[
        TIME_BASED_PRICE_LABELS[targetDuration.secondary_rate].priceName
      ];
    rateCombinationTotal += targetDuration.secondary_multiplier * secondaryRate;
  }

  const subtotal = rateCombinationTotal * rentalItem.quantity;

  // const adjustedTotals = calculateAdjustedTotals(
  //   subtotal,
  //   rentalItem,
  //   forBundle
  // );

  return {
    total: subtotal,
    totalAfterAdjustments: subtotal, // TODO: copy afterAdjustments logic => rental_item.selectedPrice & rental_item.selectedPriceRaw
    duration: 3, // ?? e.g. 6 (based on hours? maybe first part of duration) TODO: return both durations used?
    period: 'advanced_pricing',
    advancedPricingPrimaryRateValue: primaryRate,
    advancedPricingPrimaryRate: targetDuration.rate,
    advancedPricingPrimaryMultiplier: targetDuration.multiplier,
    advancedPricingSecondaryRate: targetDuration.secondary_rate,
    advancedPricingSecondaryMultiplier: targetDuration.secondary_multiplier,
    advancedPricingSecondaryRateValue: secondaryRate,
  };
};

export const showSelectedPrice = (
  product,
  quantity,
  startDateTime,
  endDateTime,
  productType,
  location
) => {
  const timeDiffms = new Date(endDateTime) - new Date(startDateTime);
  let timeDiffhours = timeDiffms / 1000 / 60 / 60;
  timeDiffhours = timeDiffhours > 0 ? timeDiffhours : 0;

  const prices = [
    'hourlyPrice',
    'halfDayPrice',
    'dailyPrice',
    'weeklyPrice',
    'monthlyPrice',
    'flatPrice',
  ];

  const usablePrices = prices.map((price, index) => {
    let newPrices = prices.slice();
    newPrices.splice(index, 1);

    if (product[price] && product[price] !== null) {
      return {
        price: product[price],
        unit: price,
      };
    } else {
      const modifiedPrice = newPrices.find((testprice) => {
        return product[testprice] != null;
      });
      if (modifiedPrice !== undefined) {
        return {
          price: product[modifiedPrice],
          unit: modifiedPrice,
        };
      } else {
        const lastAvailablePrice = prices
          .slice()
          .reverse()
          .find((testprice) => product[testprice] != null);
        return {
          price: product[lastAvailablePrice],
          unit: lastAvailablePrice,
        };
      }
    }
  });
  
  const smartPricingActive =
    location.smartPricingActive || location.smart_pricing_active;

  const rentalItemHasAllTimePrices =
    !!product.hourlyPrice &&
    !!product.halfDayPrice &&
    !!product.dailyPrice &&
    !!product.weeklyPrice &&
    !!product.monthlyPrice;

  const rentalItemIsTimeBased = product.defaultPricing === 'time-based';

  const { advancedPricingEnabled, orderDurations } = location;

  // When settings of item is 2nd. Per-unit cost
  const isFlatUnitPrice =
    (product.period === 'edited_flat_unit_price' ||
      product.period === 'flat_unit_price') &&
    product.setManually;
  // When settings on items is set to 3rd. Total cost
  const isEditedFlatPrice = product.period === 'edited_flat_price';

  // Strange combination found on order, https://app.asana.com/0/1147937635440155/1200197907044907
  const strangeEditedFlatPriceNonManually =
    (product.period === 'edited_flat_unit_price' ||
      product.period === 'flat_unit_price') &&
    !product.setManually;

  let priceUnit;
  if (hasSelectedFlatPrice(product, productType)) {
    priceUnit = 'standardFlatPrice';
  } else if (product.pricing && +product.pricing > 0) {
    priceUnit = 'pricing';
  } else if (
    !product.hourlyPrice &&
    !product.halfDayPrice &&
    !product.dailyPrice &&
    !product.weeklyPrice &&
    !product.monthlyPrice
  ) {
    if (productType === 'bundles' && product.purchasePrice) {
      return {
        rate: product.purchasePrice,
        total: 0,
        period: 'flat_unit_price',
      };
    } else {
      priceUnit = 'flatPrice';
    }
  } else {
    priceUnit = getPriceUnit(timeDiffhours, startDateTime, endDateTime);
  }
  let duration;
  let usablePrice;
  let remainder;
  let minHours;
  let remainderTier;
  let additionalSmartPricingRate = 0;
  let rates;

  product.quantity = quantity;
  // ----- ADVANCED PRICING --------
  if (
    rentalItemHasAllTimePrices &&
    rentalItemIsTimeBased &&
    advancedPricingEnabled &&
    orderDurations.length &&
    !(isEditedFlatPrice || strangeEditedFlatPriceNonManually || isFlatUnitPrice)
  ) {
    let selected = getDepartmentPricesAndTotal(
      {
        orderDurations,
        eventStartDateTime: startDateTime,
        eventEndDateTime: endDateTime,
      },
      product
    );
    return {
      total: selected.total,
      duration: selected.duration,
      rate: selected.advancedPricingPrimaryRateValue,
      period:
        periods[productTierPriceHash[selected.advancedPricingPrimaryRate]],
    };
  }
  // ----- END ADVANCED PRICING ------
  switch (priceUnit) {
    case 'hourly':
      usablePrice = usablePrices[0];
      duration = calculateDuration(usablePrice.unit, timeDiffhours);
      if (productHasExistingRate(product, 'hourlyPrice')) {
        return calculateExistingPrice(
          product[usablePrice.unit],
          smartPricingActive ? Math.floor(duration) : Math.round(duration),
          quantity,
          usablePrice
        );
      } else {
        return calculateAlternativePrice({
          usablePrice,
          quantity,
          additionalSmartPricingRate,
          priceUnit,
          usablePrices,
          product,
          timeDiffhours,
          startDateTime,
          endDateTime,
          smartPricingActive,
        });
      }
    case 'halfDay':
      usablePrice = usablePrices[1];
      duration = calculateDuration(usablePrice.unit, timeDiffhours);
      remainder = calculateRemainingTime(priceUnit, timeDiffhours);
      remainderTier = getPriceUnit(remainder);
      // hourly
      minHours = { closest: hours.hourly };

      if (productHasExistingRate(product, 'halfDayPrice')) {
        if (smartPricingActive) {
          const smartPricing = smartPricingAvailableWithinTierRange(
            priceUnit,
            remainderTier,
            product,
            remainder,
            minHours,
            smartPricingActive
          );
          return calculateSmartPricing(
            smartPricing,
            quantity,
            remainder,
            additionalSmartPricingRate,
            product,
            usablePrice,
            duration
          );
        } else {
          return calculateExistingPrice(
            product[usablePrice.unit],
            smartPricingActive ? Math.floor(duration) : Math.round(duration),
            quantity,
            usablePrice
          );
        }
      } else {
        return calculateAlternativePrice({
          usablePrice,
          quantity,
          additionalSmartPricingRate,
          priceUnit,
          usablePrices,
          product,
          timeDiffhours,
          startDateTime,
          endDateTime,
          smartPricingActive,
        });
      }
    case 'daily':
      usablePrice = usablePrices[2];
      duration = calculateDuration(usablePrice.unit, timeDiffhours);
      remainder = calculateRemainingTime(priceUnit, timeDiffhours);
      remainderTier = getPriceUnit(remainder);
      minHours = {
        // half day
        closest: hours.halfDay,
        // hourly
        furthest: hours.hourly,
      };

      if (productHasExistingRate(product, 'dailyPrice')) {
        if (smartPricingActive) {
          const smartPricing = smartPricingAvailableWithinTierRange(
            priceUnit,
            remainderTier,
            product,
            remainder,
            minHours,
            smartPricingActive
          );

          return calculateSmartPricing(
            smartPricing,
            quantity,
            remainder,
            additionalSmartPricingRate,
            product,
            usablePrice,
            duration
          );
        }
        return calculateExistingPrice(
          product[usablePrice.unit],
          smartPricingActive ? Math.floor(duration) : Math.round(duration),
          quantity,
          usablePrice
        );
      } else {
        return calculateAlternativePrice({
          usablePrice,
          quantity,
          additionalSmartPricingRate,
          priceUnit,
          usablePrices,
          product,
          timeDiffhours,
          startDateTime,
          endDateTime,
          smartPricingActive,
        });
      }
    case 'weekly':
      usablePrice = usablePrices[3];
      duration = calculateDuration(usablePrice.unit, timeDiffhours);
      remainder = calculateRemainingTime(priceUnit, timeDiffhours);
      remainderTier = getPriceUnit(remainder);
      minHours = {
        // daily
        closest: hours.daily,
        // half day
        furthest: hours.halfDay,
      };

      if (productHasExistingRate(product, 'weeklyPrice')) {
        if (smartPricingActive) {
          const smartPricing = smartPricingAvailableWithinTierRange(
            priceUnit,
            remainderTier,
            product,
            remainder,
            minHours,
            smartPricingActive
          );

          return calculateSmartPricing(
            smartPricing,
            quantity,
            remainder,
            additionalSmartPricingRate,
            product,
            usablePrice,
            duration
          );
        } else {
          return calculateExistingPrice(
            product[usablePrice.unit],
            smartPricingActive ? Math.floor(duration) : Math.round(duration),
            quantity,
            usablePrice
          );
        }
      } else {
        return calculateAlternativePrice({
          usablePrice,
          quantity,
          additionalSmartPricingRate,
          priceUnit,
          usablePrices,
          product,
          timeDiffhours,
          startDateTime,
          endDateTime,
          smartPricingActive,
        });
      }
    case 'monthly':
      usablePrice = usablePrices[4];
      const startMonth = startDateTime.getMonth();
      let endMonth = endDateTime.getMonth();
      if (endDateTime.getYear() > startDateTime.getYear()) {
        const differenceInYears =
          endDateTime.getYear() - startDateTime.getYear();
        endMonth += differenceInYears * 12;
      }
      const monthsRange = range(startMonth, endMonth);
      const monthsBeingRented = monthsRange.slice(0, -1);
      const hoursInMonthsBeingRented =
        calculateHoursForEachMonth(monthsBeingRented);
      remainder = timeDiffhours - hoursInMonthsBeingRented;
      duration = calculateDuration(usablePrice.unit, timeDiffhours - remainder);
      remainderTier = getPriceUnit(remainder, startDateTime, endDateTime);
      minHours = {
        // weekly
        closest: hours.weekly,
        //daily
        furthest: hours.daily,
      };

      if (
        productHasExistingRate(product, 'monthlyPrice') &&
        hoursInMonthsBeingRented > 0
      ) {
        if (smartPricingActive) {
          const smartPricing = smartPricingAvailableWithinTierRange(
            priceUnit,
            remainderTier,
            product,
            remainder,
            minHours,
            smartPricingActive
          );

          if (smartPricing) {
            const { tier, rate } = smartPricing;
            remainder = calculateDuration(tier, remainder);
            // 0.1 is week remainder
            if (remainder > 1) {
              remainder = Math.ceil(remainder);
            }

            remainder = Math.floor(remainder);
            additionalSmartPricingRate = getPrice(remainder, rate, quantity);
          } else if (!smartPricing && remainder > 0) {
            additionalSmartPricingRate = roundPricing(
              remainder,
              product,
              'monthly'
            );
          } else {
            duration = roundDurationIfNeeded(
              usablePrice,
              remainder,
              monthsBeingRented,
              duration,
              remainderTier
            );
          }

          return calculateExistingPrice(
            product[usablePrice.unit],
            smartPricingActive ? Math.floor(duration) : Math.round(duration),
            quantity,
            usablePrice,
            additionalSmartPricingRate
          );
        } else {
          duration = roundDurationIfNeeded(
            usablePrice,
            remainder,
            monthsBeingRented,
            duration
          );
          return calculateExistingPrice(
            product[usablePrice.unit],
            smartPricingActive ? Math.floor(duration) : Math.round(duration),
            quantity,
            usablePrice
          );
        }
      } else {
        return calculateAlternativePrice({
          usablePrice,
          quantity,
          additionalSmartPricingRate,
          priceUnit,
          usablePrices,
          product,
          timeDiffhours,
          startDateTime,
          endDateTime,
          smartPricingActive,
        });
      }
    case 'standardFlatPrice':
      const selectedFlatPrice = getSelectedFlatPrice(product);
      if (selectedFlatPrice) {
        return {
          rate: selectedFlatPrice.amount,
          total: selectedFlatPrice.amount * quantity,
          duration: 1,
          period: `standard_flat_price[${selectedFlatPrice.name}]`,
        };
      } else {
        return null;
      }
    case 'flatUnitPrice':
      return {
        rate: product.flatUnitPrice,
        total: product.flatUnitPrice * quantity,
        duration: 1,
        period: 'flat_unit_price',
      };
    case 'pricing':
    default:
      return {
        rate: product.pricing,
        total: product.pricing * quantity,
        period: 'flat_unit_price',
      };
  }
};

export const showSelectedPriceBundle = (
  rentalBundle,
  quantity,
  startTime,
  endTime,
  location
) => {
  // if bundle is locked & has selected a flat price, return that
  if (hasSelectedFlatPrice(rentalBundle, 'bundles')) {
    const selectedFlatPrice = getSelectedFlatPrice(rentalBundle);

    return {
      rate: selectedFlatPrice.amount,
      duration: 1,
      period: rentalBundle.bundle.defaultPricing,
      total: selectedFlatPrice.amount * quantity,
    };
  }

  const selected = showSelectedPrice(
    rentalBundle,
    quantity,
    startTime,
    endTime,
    'bundles',
    location
  );
  const totalPrice =
    selected.total * (1 - Number(rentalBundle.discountPercent));

  return {
    rate: selected.rate,
    duration: selected.duration,
    period: selected.period,
    total: totalPrice,
  };
};

export const getRentalBundleWithPrice = (
  rentalBundle,
  startTime,
  endTime,
  location
) => {
  let newRentalBundle = { ...rentalBundle };
  let period = newRentalBundle.period;
  if (!(period && period.includes('standard_flat_price'))) {
    period = null;
  }

  newRentalBundle.location =
    newRentalBundle.bundle.location || rentalBundle.location;

  const selected = showSelectedPriceBundle(
    newRentalBundle,
    newRentalBundle.quantity,
    startTime,
    endTime,
    location
  );
  newRentalBundle.selectedPrice = selected.total;
  newRentalBundle.selectedRate = selected.rate;
  newRentalBundle.duration = selected.duration;
  newRentalBundle.period = selected.period;

  if (!newRentalBundle.priceLocked) {
    newRentalBundle = getUnlockedRentalBundle(
      newRentalBundle,
      startTime,
      endTime,
      location
    );
  }

  return newRentalBundle;
};

const getUnlockedRentalBundle = (
  rentalBundle,
  startTime,
  endTime,
  location
) => {
  const oldRentalBundle = rentalBundle;
  let newRentalBundle = { ...oldRentalBundle };
  if (newRentalBundle._destroy === '1') {
    // no need to recalculate if bundle is destroyed
    return newRentalBundle;
  }
  const newRentalItems = newRentalBundle.rentalItems.map((item) => {
    if (
      (item.period === 'edited_flat_unit_price' ||
        item.period === 'flat_unit_price') &&
      item.setManually
    ) {
      return item;
    } else if (item.period === 'edited_flat_price') {
      return item;
    }

    // let period = item.period;
    // period =
    //   item.periodIsManuallyChanged ||
    //   (period && period.includes("standard_flat_price"))
    //     ? item.period
    //     : null;

    const selected = showSelectedPrice(
      item,
      item.quantity,
      startTime,
      endTime,
      'items',
      location
    );
    const selectedPrice =
      selected.total * (1 - newRentalBundle.discountPercent);
    item.selectedPrice = selectedPrice;
    item.selectedPriceBeforeDiscount = selectedPrice;
    item.selectedRate = selectedPrice / selected.duration;
    item.duration = selected.duration;
    item.period = selected.period;
    return item;
  });
  const newRentalAddOns = newRentalBundle.rentalAddOns.map((addOn) => {
    if (!addOn.setManually) {
      addOn.selectedPrice =
        Number(addOn.pricing) *
        addOn.quantity *
        (1 - newRentalBundle.discountPercent);
      addOn.selectedRate =
        Number(addOn.pricing) * (1 - newRentalBundle.discountPercent);
    }
    const newRentalAddOn = Object.assign(addOn);
    addOn = newRentalAddOn;
    return addOn;
  });
  newRentalBundle.rentalItems = newRentalItems;
  newRentalBundle.rentalAddOns = newRentalAddOns;
  newRentalBundle.priceLocked = false;
  newRentalBundle.selectedPrice = calculateRentalBundlePrice(newRentalBundle);
  return newRentalBundle;
};

const calculateRentalBundlePrice = (rentalBundle) => {
  let selectedPrice = 0;
  selectedPrice += rentalBundle.rentalItems
    .filter((item) => item._destroy !== '1')
    .reduce((sum, ri) => {
      return sum + parseFloat(ri.selectedPrice || 0);
    }, 0);
  selectedPrice += rentalBundle.rentalAddOns
    .filter((item) => item._destroy !== '1')
    .reduce((sum, ri) => {
      return sum + parseFloat(ri.selectedPrice || 0);
    }, 0);

  return selectedPrice;
};

export const getUpdatedRentalBundle = (e, rentalBundle, event) => {
  const { name, value } = e.target;
  const { eventStart, eventStartTime, eventEnd, eventEndTime, location } =
    event;

  let newRentalBundle = {
    ...rentalBundle,
    [name]: toNumber(value),
  };

  /** Update quantities of underlying bundle inventories **/
  const newBundleQuantity = Number(newRentalBundle.quantity);
  newRentalBundle.rentalItems = newRentalBundle.rentalItems.map(
    (rentalItem) => {
      if (!rentalItem.setManually) {
        const container =
          newRentalBundle.bundle.productBundleRelationships.find(
            (pbr) => pbr.productId === rentalItem.productId
          );
        rentalItem.quantity = newBundleQuantity * container.quantity;
      }
      return rentalItem;
    }
  );

  newRentalBundle.rentalAccessories = newRentalBundle.rentalAccessories.map(
    (rentalAccessory) => {
      if (!rentalAccessory.setManually) {
        const container =
          newRentalBundle.bundle.accessoryBundleRelationships.find(
            (pbr) => pbr.accessoryId === rentalAccessory.accessoryId
          );
        rentalAccessory.quantity = newBundleQuantity * container.quantity;
      }
      return rentalAccessory;
    }
  );

  newRentalBundle.rentalAddOns = newRentalBundle.rentalAddOns.map(
    (rentalAddOn) => {
      if (!rentalAddOn.setManually) {
        const container = newRentalBundle.bundle.addOnBundleRelationships.find(
          (pbr) => pbr.addOnId === rentalAddOn.addOnId
        );
        rentalAddOn.quantity = newBundleQuantity * container.quantity;
      }
      return rentalAddOn;
    }
  );
  /** Update quantities of underlying bundle inventories **/

  let rentalBundleWithPrice = getRentalBundleWithPrice(
    newRentalBundle,
    combineDateAndTime(eventStart, eventStartTime),
    combineDateAndTime(eventEnd, eventEndTime),
    location
  );
  return rentalBundleWithPrice;
};

// configure duration return values, e.g. "a day" to "1 day" (removes splitting actions needed below)
// confirm / configure moment's month breakpoint at 30-days instead of 25-days
const configureMomentRelativeTime = () => {
  moment.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: '1_hour',
      hh: '%d_hours',
      d: '1_day',
      dd: '%d_days',
      w: '1_week',
      ww: '%d_weeks',
      M: '1_month',
      MM: '%d_months',
      y: '1_year',
      yy: '%d_years',
    },
  });

  // TODO: move this somewhere where it does not need to be called each time
  // sets moment breakpoints to match Order Durations on DB
  moment.relativeTimeThreshold('h', 24); // split at 23_hours to 1_day
  moment.relativeTimeThreshold('d', 31); // split at 30_days to 1_month
  moment.relativeTimeThreshold('M', 13); // split at 12_months to 1_year
};

// ----- function relative to ignore weekends toggle for advanced pricing departments ------
function calculateTotalWeekends(startDate, endDate) {
  const days = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  const saturdays = Math.floor((startDate.getDay() + days) / 7);
  const endDateIsSaturday = endDate.getDay() == 6 ? 1 : 0;
  const startDateIsSaturday = startDate.getDay() == 6 ? -2 : 0;
  return 2 * saturdays + endDateIsSaturday + startDateIsSaturday;
}

function isGreaterThanOneWeek(date1, date2) {
  const oneWeekInMilliseconds = 604800000; // 7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
  const differenceInMilliseconds = Math.abs(date2 - date1);
  const differenceInWeeks = differenceInMilliseconds / oneWeekInMilliseconds;

  return differenceInWeeks >= 1;
}

function shouldCalculateTotalWeekends(startDate, endDate, hasWeekendsBetween) {
  return (
    isGreaterThanOneWeek(startDate, endDate) ||
    (hasWeekendsBetween && startDate.getDay() !== 6 && endDate.getDay() !== 0)
  );
}

function calculateDays(startDate, endDate) {
  let weekDays = [];
  let hasWeekendsBetween = false;
  let currentDate = new Date(startDate);
  if (startDate.getDay() == 6) {
    currentDate = new Date(moment(startDate).add(2, 'days').startOf('day'));
  }
  if (startDate.getDay() == 0) {
    currentDate = new Date(moment(startDate).add(1, 'days').startOf('day'));
  }
  while (currentDate <= endDate) {
    let currentDateCopy = new Date(currentDate);
    const isLastFullDay =
      new Date(currentDateCopy.setDate(currentDateCopy.getDate() + 1)) >
      endDate;
    if (isLastFullDay) {
      let remainingHours = endDate - currentDate; // in case of < 24 hours remaining, grab remaining hours
      const lastDateWithRemainingHours = new Date(
        currentDate.getTime() + remainingHours
      );

      if (lastDateWithRemainingHours.getDay() == 6) {
        //if it's saturday push last friday
        weekDays.push(
          moment(lastDateWithRemainingHours).subtract(1, 'days').endOf('day')
        );
      } else if (lastDateWithRemainingHours.getDay() == 0) {
        //if it's sunday push last friday
        weekDays.push(
          moment(lastDateWithRemainingHours).subtract(2, 'days').endOf('day')
        );
      } else {
        weekDays.push(moment(lastDateWithRemainingHours)); // in case it's not a weekend, add remaining days to last date
      }
      currentDate.setDate(currentDate.getDate() + 1);
    } else {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        // check if full day is not a weekend
        weekDays.push(moment(currentDate));
      } else {
        hasWeekendsBetween = true;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    if (currentDate == endDate) {
      if (currentDate.getDay() == 6) {
        weekDays.push(moment(currentDate).subtract(1, 'days').endOf('day'));
      }
      if (currentDate.getDay() == 0) {
        weekDays.push(moment(currentDate).subtract(2, 'days').endOf('day'));
      }
    }
  }
  /* check if there is weekends that should be ignored in case of date range
    being less than a week but contains weekends in between */
  let totalWeekends = 0;
  if (shouldCalculateTotalWeekends(startDate, endDate, hasWeekendsBetween)) {
    totalWeekends = calculateTotalWeekends(startDate, endDate);
    if (endDate.getDay() == 0) {
      totalWeekends -= 2;
    }
    if (endDate.getDay() == 6) {
      totalWeekends -= 1;
    }
  }

  return { weekDays, totalWeekends };
}
// ----- END function relative to ignore weekends toggle for advanced pricing departments ------
