/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { setFilterParams, filterFromPropsAndState } from "HelperFunctions/urls";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import EventForm from "../cart/EventForm";
import LoadingSpinner from "Utils/LoadingSpinner";
import Modal from "Utils/Modal";
import Validator from "HelperFunctions/validator";
import { combineDateAndTime } from "HelperFunctions/general";
import CartBilling from "../cart/CartBilling";
import CartItems from "../cart/CartItems";

class EditEvent extends React.Component {
  state = {
    cancelChangesModalOpen: false,
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      fetchStorefrontEditPayload,
      setCartProps,
      event
    } = this.props;
    if (!event.editingEvent) {
      setCartProps({ editingEvent: false}); // This triggers the loading spinner until event is loaded
      fetchStorefrontEditPayload(id);
    }
  }
  
  handleSelectStep = step => {
    const { location, history } = this.props;
    setFilterParams(
      {
        step: step
      },
      location,
      history
    );
  };

  handleSubmitQuote = () => {
    const { event, editSubmit, requestChanges, setErrors, clearCartProps } = this.props;

    let eventForValidation = {
      ...event,
      eventStartDateTime: combineDateAndTime(
        event.eventStart,
        event.eventStartTime
      ),
      eventEndDateTime: combineDateAndTime(event.eventEnd, event.eventEndTime)
    };

    const eventValidations = {
      eventEndDateTime: {
        greaterThanOrEqualToDate: {
          value: eventForValidation.eventStartDateTime,
          message: "End date must be after start date."
        }
      }
    };

    const eventErrors = Validator(eventValidations)(eventForValidation);

    if (Object.keys(eventErrors).length > 0) {
      setErrors(eventErrors)
    } else if (event.inventoryOnCart.map(i => i.quantity).indexOf("0") === -1) {
      if (event.approvalStatus === "pending") {
        editSubmit(event => {
          clearCartProps();
          this.eventRedirect(event);
        });
      } else {
        requestChanges(event => {
          clearCartProps();
          this.eventRedirect(event);
        });
      }
    } else {
      setErrors("Quantity must be positive number. Press the x button to remove an item");
    }

  };

  cancelEditedChanges = () => {
    const { event, history, clearCartProps } = this.props;

    clearCartProps();

    history.push(`/events/${event.token}`);
  };

  eventRedirect = event => {
    // this.handleSelectStep(4);
    this.props.history.push(`/events/${event.token}`);
  };

  toggleCancelChangesModal = () => {
    this.setState({
      cancelChangesModalOpen: !this.state.cancelChangesModalOpen
    })
  }

  redirectToEventPage = () => {
    const { event, history } = this.props;
    history.push(`/events/${event.token}`);
  }

  renderNextButton = () => {
    return (
      <a className="btn secondary" onClick={this.handleSubmitQuote}>
        Request Edits
      </a>
    );
  };

  render() {
    const { event, step } = this.props;

    if (!event || !event.editingEvent) {
      return <LoadingSpinner />;
    } else {
      return (
        <div className="cart">
          <div className="content editEvent">
            <section
              className={classnames({
                progress: true
              })}
            >
              <div className="editOrder">
                <span>
                  <b>Editing Order: {event.name}</b> #{event.token}
                </span>
              </div>
              <div className='editBtns'>
                {step <= 3 &&
                  <a
                    className="btn skip-step"
                    onClick={this.toggleCancelChangesModal}
                  >
                    Cancel Changes
                  </a>
                }
                {this.renderNextButton()}
              </div>
            </section>
            <CartItems/>
            <EventForm />
            <CartBilling step={step} />
          </div>
          <Modal
            className="cancelChangeRequest"
            title="Cancel Changes"
            open={this.state.cancelChangesModalOpen}
            toggle={this.toggleCancelChangesModal}
          >
            <div>
              <p>
                Looks like you’ve made some changes to your order. Are you sure you want to cancel them?
              </p>
              <div className="actions">
                <a className="btn seconday" onClick={this.cancelEditedChanges}>
                  Yes, Cancel My Changes
                </a>
                <a className="btn cancel" onClick={this.toggleCancelChangesModal}>
                  Go Back
                </a>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const newFilter = filterFromPropsAndState(ownProps);
  const step = newFilter.step ? +newFilter.step : 1;
  const { event } = state.cart;
  return { event, step };
};

export default connect(mapStateToProps, actions)(EditEvent);
