import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { createMemoryHistory, createBrowserHistory } from 'history';
import { ConnectedRouter } from 'react-router-redux';
import MainMenu from 'Components/main_menu/MainMenu';
import Trim from 'Components/home/Trim';
import SignUp from 'Components/home/SignUp';
import SignIn from 'Components/home/SignIn';
import ReclaimAccount from 'Components/home/ReclaimAccount';
import ConnectAccount from 'Components/home/ConnectAccount';
import ForgotPassword from 'Components/home/ForgotPassword';
import PasswordReset from 'Components/home/PasswordReset';
import NoBusinessFound from 'Components/home/NoBusinessFound';
import UnathorizedOrder from 'Components/home/UnathorizedOrder';
import ProfileSection from 'Components/profile/ProfileSection';
import Conversations from 'Components/conversations/Conversations';
import ShopSection from 'Components/shop/Shop';
import SearchItems from 'Components/shop/SearchItems';
import GetTheLook from 'Components/get_the_look/GetTheLook';
import Favorites from 'Components/get_the_look/Favorites';
import EventsSection from 'Components/events/EventsSection';
import Cart from 'Components/cart/Cart'
import CartConfirmation from 'Components/cart/CartConfirmation';
import Homepage from 'Components/home/Homepage';
import Gallery from 'Components/home/Gallery';
import Home from 'Components/home/Home';
import Aboutus from 'Components/home/Aboutus';
import Agreement from 'Components/home/Agreement';
import Contact from 'Components/home/Contact';
import Faq from 'Components/home/Faq';
import ContactConfirmation from 'Components/home/ContactConfirmation';
import Footer from 'Components/home/Footer';
import Showcase from 'Components/home/Showcase';
import OurVendors from 'Components/home/OurVendors';
import NewFeedback from 'Components/feedbacks/NewFeedback';
import FeedbackThankYou from 'Components/feedbacks/FeedbackThankYou';
import MakeAPayment from 'Components/guest_checkout/MakeAPayment';
import App from './App';
import ReactGA from 'react-ga';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import LoadingSpinner from 'Utils/LoadingSpinner';
import { parseQuery } from 'HelperFunctions/QueryString';
import WithAuth from 'Components/../hocs/WithAuth';
import ScrollToTop from 'Utils/ScrollToTop';
import UnsupportedRoute from 'Utils/UnsupportedRoute';
import { isSupportedBrowser } from 'HelperFunctions/general';
import { isSsr } from 'HelperFunctions/ssr';
import Tracking from 'Utils/Tracking';

const EventSectionWithAuth = WithAuth(EventsSection);

export const history = !isSsr()
  ? createBrowserHistory()
  : createMemoryHistory();

if (!isSsr()) {
  function logPageView() {
    if (!isSsr()) {
      ReactGA.set({ page: window.location.pathname + window.location.search }, [
        'clientTracker',
      ]);
      ReactGA.pageview(window.location.pathname + window.location.search, [
        'clientTracker',
      ]);
      ReactGA.ga('clientTracker.send', 'pageview', window.location.pathname);
    }
  }
  history.listen((location, action) => {
    logPageView();
  });
}

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.signInStoredUserIfNeeded = this.signInStoredUserIfNeeded.bind(this);
  }

  componentDidMount() {
    this.unlisten = history.listen((historyLocation, action) => {
      if (historyLocation.pathname === '/conversations') {
        const { fetchConversationList, location } = this.props;
        fetchConversationList(location.id);
      }
    });

    if (
      !isSsr() &&
      window.location.origin === process.env.REACT_APP_FRONT_END_DOMAIN &&
      window.location.pathname === '/makeAPayment' &&
      window.location.search &&
      parseQuery(window.location.search).token
    ) {
      const token = parseQuery(window.location.search).token;
      this.props.fetchLocationFromToken(token);
    } else {
      this.signInStoredUserIfNeeded();
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  signInStoredUserIfNeeded = () => {
    // Now you can access the store object here.
    const { store } = this.props;
    const state = store.getState();

    const { authenticated } = state.auth;

    if (!authenticated) {
      // Not authenticated, redirect to login.
      store.dispatch(actions.refreshStoredUser());
    }
  };

  render() {
    if (!isSupportedBrowser()) {
      return <UnsupportedRoute history={history} />;
    }

    const { storeLocation, loading, parsedStorefrontSettings } = this.props;

    const renderFirstRoute = () => {
      let component = Homepage;

      if (storeLocation.sfSubscriptionTier === 'basic') {
        component = EventSectionWithAuth;
      } else if (storeLocation.sfSubscriptionTier === 'cart') {
        component = parsedStorefrontSettings.storefrontShopSetting.shopVisible
          ? SearchItems
          : EventSectionWithAuth;
      }

      return <Route exact path='/' component={component} />;
    };

    return (
      <ConnectedRouter history={history}>
        <ScrollToTop>
          {loading ? (
            <LoadingSpinner />
          ) : storeLocation === null ? (
            <Route component={NoBusinessFound} />
          ) : (
            <Switch>
              <Route exact path='/notAuthorized' component={UnathorizedOrder} />
              <Route
                render={(props) => (
                  <div className='container'>
                    <Route path='/' component={App} />
                    <Route path='/' component={MainMenu} />

                    <main>
                      <Switch>
                        {renderFirstRoute()}
                        {parsedStorefrontSettings.storefrontPages &&
                          parsedStorefrontSettings.storefrontPages.map(
                            (storefrontPage, index) => {
                              switch (storefrontPage.pageType) {
                                case 'home':
                                  return (
                                    storefrontPage.pageVisible && (
                                      <Route
                                        exact
                                        path={`/${storefrontPage.navigationLink}`}
                                        component={Home}
                                      />
                                    )
                                  );
                                case 'about-us':
                                  return (
                                    storefrontPage.pageVisible && (
                                      <Route
                                        exact
                                        path={`/${storefrontPage.navigationLink}`}
                                        component={Aboutus}
                                      />
                                    )
                                  );
                                case 'gallery':
                                  return (
                                    storefrontPage.pageVisible && (
                                      <Route
                                        exact
                                        path={`/${storefrontPage.navigationLink}`}
                                        component={Gallery}
                                      />
                                    )
                                  );
                                case 'contact':
                                  return (
                                    storefrontPage.pageVisible && (
                                      <Route
                                        exact
                                        path={`/${storefrontPage.navigationLink}`}
                                        component={Contact}
                                      />
                                    )
                                  );
                                case 'faq':
                                  return (
                                    storefrontPage.pageVisible && (
                                      <Route
                                        exact
                                        path={`/${storefrontPage.navigationLink}`}
                                        component={Faq}
                                      />
                                    )
                                  );
                                case 'category-showcase':
                                  return (
                                    storefrontPage.pageVisible && (
                                      <Route
                                        exact
                                        path={`/${storefrontPage.navigationLink}`}
                                        component={Showcase}
                                      />
                                    )
                                  );
                                case 'connections':
                                  return (
                                    storefrontPage.pageVisible && (
                                      <Route
                                        exact
                                        path={`/${storefrontPage.navigationLink}`}
                                        component={OurVendors}
                                      />
                                    )
                                  );
                              }
                            }
                          )}
                        {parsedStorefrontSettings.storefrontContactSetting &&
                          parsedStorefrontSettings.storefrontContactSetting
                            .contactVisible && (
                            <Route
                              exact
                              path='/contact/confirmation'
                              component={ContactConfirmation}
                            />
                          )}
                        <Route path='/GettheLook' component={GetTheLook} />
                        <Route
                          path='/favorites'
                          component={WithAuth(Favorites)}
                        />
                        <Route
                          exact
                          path='/makeAPayment'
                          component={MakeAPayment}
                        />
                        <Route exact path='/agreement' component={Agreement} />
                        <Route
                          exact
                          path='/feedbacks'
                          component={NewFeedback}
                        />
                        <Route path='/feedbacks/new' component={NewFeedback} />
                        <Route
                          path='/feedbacks/thankyou'
                          component={FeedbackThankYou}
                        />
                        <Route path='/login' component={SignIn} />
                        <Route path='/signUp' component={SignUp} />
                        <Route
                          path='/reclaimAccount'
                          component={ReclaimAccount}
                        />
                        <Route
                          path='/connectAccount'
                          component={ConnectAccount}
                        />
                        <Route
                          path='/forgotPassword'
                          component={ForgotPassword}
                        />
                        <Route
                          path='/passwordReset'
                          component={PasswordReset}
                        />
                        <Route
                          path='/profile'
                          component={WithAuth(ProfileSection)}
                        />
                        {parsedStorefrontSettings.storefrontShopSetting &&
                          parsedStorefrontSettings.storefrontShopSetting
                            .shopVisible &&
                          storeLocation.sfSubscriptionTier != 'basic' && (
                            <Route path='/shop' component={ShopSection} />
                          )}

                        {!isSsr() && (
                          <Route
                            path='/conversations'
                            component={WithAuth(Conversations)}
                          />
                        )}
                        {parsedStorefrontSettings.storefrontShopSetting &&
                          parsedStorefrontSettings.storefrontShopSetting
                            .shopVisible &&
                          storeLocation.sfSubscriptionTier != 'basic' && (
                            <Route exact path='/cart' component={Cart} />
                          )}
                        {parsedStorefrontSettings.storefrontShopSetting &&
                          parsedStorefrontSettings.storefrontShopSetting
                            .shopVisible && (
                            <Route
                              exact
                              path='/cart/confirmation'
                              component={CartConfirmation}
                            />
                          )}

                        <Route
                          path='/events'
                          component={EventSectionWithAuth}
                        />
                      </Switch>
                    </main>
                    <Route path='/' component={Trim} />
                    <Footer />
                    <Tracking
                      parsedStorefrontSettings={parsedStorefrontSettings}
                    />
                  </div>
                )}
              />
            </Switch>
          )}
        </ScrollToTop>
      </ConnectedRouter>
    );
  }
}
const mapStateToProps = (state) => {
  const { location, loading, parsedStorefrontSettings } = state.locations;
  return { storeLocation: location, loading, parsedStorefrontSettings };
};
export default connect(mapStateToProps, actions)(Routes);
