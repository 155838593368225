import { combineReducers } from 'redux';
import AdminAuthReducer from './AdminAuthReducer';
import AdminCustomersReducer from './AdminCustomersReducer';
import AuthReducer from './AuthReducer';
import LoadingSpinnerReducer from './LoadingSpinnerReducer';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import CustomerReducer from './CustomerReducer';
import CalendarReducer from './CalendarReducer';
import RentalReducer from './RentalReducer';
import RentalsReducer from './RentalsReducer';
import LocationsReducer from './LocationsReducer';
import ConversationListReducer from './ConversationListReducer';
import ConversationReducer from './ConversationReducer';
import MessageAlertReducer from './MessageAlertReducer';
import GuestCheckoutReducer from './GuestCheckoutReducer';

import CategoryListReducer from './CategoryListReducer';
import ColorListReducer from './ColorListReducer';
import ProductReducer from './ProductReducer';
import ProfileReducer from './ProfileReducer';
import MessageableReducer from './MessageableReducer';
import RentalListReducer from './RentalListReducer';
import DashboardReducer from './dashboard';
import CartReducer from './CartReducer';
import CustomPagesReducer from './CustomPagesReducer';
import AvailabilityReducer from './AvailabilityReducer';

const mainReducer = combineReducers({
  auth: AuthReducer,
  admin_auth: AdminAuthReducer,
  adminCustomers: AdminCustomersReducer,
  routing: routerReducer,
  loadingSpinner: LoadingSpinnerReducer,
  form: formReducer,
  customer: CustomerReducer,
  rental: RentalReducer,
  rentals: RentalsReducer,
  locations: LocationsReducer,
  conversationList: ConversationListReducer,
  conversation: ConversationReducer,
  calendar: CalendarReducer,
  messageAlerts: MessageAlertReducer,
  guestCheckout: GuestCheckoutReducer,
  categoryList: CategoryListReducer,
  colorList: ColorListReducer,
  profile: ProfileReducer,
  products: ProductReducer,
  messageable: MessageableReducer,
  rentalsList: RentalListReducer,
  dashboard: DashboardReducer,
  cart: CartReducer,
  customPages: CustomPagesReducer,
  availability: AvailabilityReducer,
});

export default mainReducer;
