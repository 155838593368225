import React from 'react';
import Routes from './Routes';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import configureStore from '../configureStore';
import * as actionTypes from 'Constants/redux';
import { setCartProps, initCartProps } from 'Actions/CartActions';
import { setErrors } from '../actions/SnackbarActions';
import { Route, Switch } from 'react-router-dom';
import AddToCart from './external/AddToCart';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import fetch from 'cross-fetch';

export const store = configureStore();

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_DOMAIN}/storefront/graphql`,
  fetch,
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: httpLink,
});

class Root extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.location) {
      const location = this.props.location;
      store.dispatch({
        type: actionTypes.FETCH_LOCATION_SUCCESS,
        location: location,
        preview: '',
      });
      store.dispatch(
        setCartProps({ location })
      );
    }

    const event = store.getState().cart.event;
    if (event && Object.keys(event).some((key) => ['items', 'addons', 'bundles'].includes(key))) {
      alert("Your cart contained expired items and has been emptied. Please add the items again.")
      store.dispatch(initCartProps())
    }

    return (
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <IntlProvider locale='en'>
            <Switch>
              <Route exact path='/addToCart' component={AddToCart} />
              <Routes store={store} location={this.props.location} />
            </Switch>
          </IntlProvider>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default Root;
