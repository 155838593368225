/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { connect } from 'react-redux';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import * as actions from 'Actions';
import { Items, Delete, Info, Alert } from 'Utils/SvgIcons';
import {
  getItemPeriodPrice,
  getCombinedItemPeriodPrice,
} from 'HelperFunctions/rentals';
import { getProductType } from 'HelperFunctions/rental_items';
import NumberInput from 'Utils/NumberInput';
import StandardTextField from 'Utils/redux_form_inputs/StandardTextField';
import ReactTooltip from 'react-tooltip';
import Imgix from 'react-imgix';
import CurrencyFormatter from 'HelperFunctions/CurrencyFormatter';
import classnames from 'classnames';
import OverbookingTooltip from '../shop/OverbookingTooltip';
import {
  getNumberAvailable,
  checkInventoryObjectAvailabilityLoading,
} from 'HelperFunctions/availability';
import { setErrors } from 'Actions';
import { setCartProps } from 'Actions/CartActions';
import { INVENTORY_TYPE_TRANSLATIONS, INVENTORY_PARENT_KEY_BY_TYPE } from '../../helper_functions/rental_items';
import { updatedInventoryOnCartAfterDefiningNewQuantity, updatedInventoryOnCartAfterRemovingInventory } from 'HelperFunctions/inventory';

class CartItem extends React.Component {
  handleQuantityUpdate = (e) => {
    const { event, setCartProps, convertStorefrontToV2PayloadAndReloadStore } = this.props;
    const { inventoryOnCart } = event;

    const newInventoryOnCart = updatedInventoryOnCartAfterDefiningNewQuantity(inventoryOnCart, this.productObject(), Number(e.target.value), !event.editingEvent)
    setCartProps({ inventoryOnCart: newInventoryOnCart });
    convertStorefrontToV2PayloadAndReloadStore();
  };

  handleItemRemove = () => {
    const { item, event, setCartProps, convertStorefrontToV2PayloadAndReloadStore } = this.props;

    const newInventoryOnCart = updatedInventoryOnCartAfterRemovingInventory(event.inventoryOnCart || [], item)

    setCartProps({ inventoryOnCart: newInventoryOnCart, cartHasChanged: true })
    convertStorefrontToV2PayloadAndReloadStore();
  };

  formatSmartPrincingLabel(rentalItem, formatter) {
    switch (rentalItem.period) {
      case 'hourly_price':
        return `${formatter.format(rentalItem.hourlyPrice)} / Hour`;
      case 'half_day_price':
        return `${formatter.format(rentalItem.halfDayPrice)} / Half-Day`;
      case 'daily_price':
        return `${formatter.format(rentalItem.dailyPrice)} / Day`;
      case 'weekly_price':
        return `${formatter.format(rentalItem.weeklyPrice)} / Week`;
      case 'monthly_price':
        return `${formatter.format(rentalItem.monthlyPrice)} / Month`;
    }
  }

  productObject = () => {
    // If loadItemsFromEvent prop is false, then this display the products on the cart. The product object comes from additionalInfo
    // key which is present on the event which was loaded on the translate storefront to v2 request
    // If loadItemsFromEvent prop is true, then this displays the products on the submitted order. The product object comes directly
    // from the item object itself because it already exists on the event and therefore is present on the item

    const { event, item, loadItemsFromEvent } = this.props;
    
    if (loadItemsFromEvent) {
      return item.product;
    }

    return event.additionalInfo?.product_infos?.find((pi) => {
      return pi.id === item.itemId && pi.productType === item.itemType;
    }) 
  }

  findBillingLineForProduct = () => {
    const { event, loadedEvent, loadItemsFromEvent, item } = this.props;

    const sourceBillingLines = loadItemsFromEvent ? loadedEvent.billingLines : event.billingLines;

    return sourceBillingLines?.find((billingLine) => {
      if (!billingLine.inventoryObject) {
        return null;
      }
      const sameEntityType = billingLine.inventoryObject && billingLine.inventoryObject.entity_type === INVENTORY_TYPE_TRANSLATIONS[item.itemType]
      const sameId = billingLine.inventoryObject[INVENTORY_PARENT_KEY_BY_TYPE[item.itemType]] === item.itemId
      return sameEntityType && sameId
    })
  }

  render() {
    const {
      item,
      storefrontShopSetting,
      tileView,
      location: { locale },
      location,
      cartConfirmation,
      availability,
      itemIndex,
      loadItemsFromEvent
    } = this.props;
    const { event } = this.props;

    const product = this.productObject();

    // Skip when product is not yet loaded
    if (!product) return <></>

    const productType = product.productType;

    const currencyFormatter = CurrencyFormatter({ locale });
    const itemQuantity = item.quantity;

    const isAvailabilityLoading = checkInventoryObjectAvailabilityLoading(
      availability,
      product?.id,
      productType
    );
    const availableProductNumber = isAvailabilityLoading
      ? 999999
      : getNumberAvailable(availability, product.id, productType);

    const isAllowedOverBookingForProduct =
      product.allowOverbooking && location.overbookingOnSf !== 'disallowed';
    const isItemQtyGreaterThanAvailableQty =
      itemQuantity > availableProductNumber;
    const overbookingDisallowed =
      isItemQtyGreaterThanAvailableQty && !isAllowedOverBookingForProduct;

    const billingLineForItem = this.findBillingLineForProduct();

    const showPrice = product.showPriceStorefront && storefrontShopSetting.shopShowItemPricing && !event.cartHasChanged;

    if (!billingLineForItem) {
      return <></>
    }

    {/* Billing lines aggregate linked and non-linked items of the same inventory as just one entity */}
    {/* Here, we want to show only the price related to the non-linked quantities of this item */}
    {/* Therefore we make a proportion of the final value considering only the non-linked quantity vs the total linked + non linked quantity */}
    const itemPricingDisplay = ((billingLineForItem.finalValue / (billingLineForItem.inventoryObject.quantity)) * itemQuantity || 0).toFixed(2)
    
    return (
      <MediaBreakpoints
        desktop={
          <tr>
            {tileView && (
              <td className='image'>
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              </td>
            )}
            <td
              className={classnames({
                itemContent: true,
              })}
            >
              <div id='itemContentContainer'>
                {
                  item.parentInventory?.id && (
                    <div style={{ width: "60px" }}></div>
                  )
                }
                {cartConfirmation || item.parentInventory?.id  ? (
                  <div style={{ width: '60px', fontWeight: 'bold' }}>
                    {itemQuantity}
                  </div>
                ) : (
                  <NumberInput
                    type='text'
                    name='quantity'
                    value={itemQuantity}
                    onChange={this.handleQuantityUpdate}
                  />
                )}

                <label style={{ width: item.parentInventory?.id ? 'calc(60% - 60px)' : '60%' }}>
                  <div id='itemPricing'>
                    { showPrice && (
                      <div>
                        @ {billingLineForItem.priceDisplay}
                      </div>
                    )}
                    <div id='itemName'>{product.name}</div>
                  </div>
                  {isItemQtyGreaterThanAvailableQty && (
                    <div
                      className={classnames({
                        info: true,
                        overbooking: overbookingDisallowed,
                      })}
                      data-tip
                      data-for={
                        isAllowedOverBookingForProduct ? 'info' : 'overbooking'
                      }
                      data-event='click'
                      data-event-off='mouseleave'
                    >
                      {isAllowedOverBookingForProduct ? <Info /> : <Alert />}
                    </div>
                  )}
                  {isItemQtyGreaterThanAvailableQty && (
                    <OverbookingTooltip
                      allowOverbooking={isAllowedOverBookingForProduct}
                      numberAvailable={availableProductNumber}
                    />
                  )}
                  {isItemQtyGreaterThanAvailableQty && (
                    <ReactTooltip
                      id='info'
                      class='tooltip bottom'
                      place='bottom'
                      type='light'
                      effect='solid'
                      overridePosition={({ _left, top }) => ({top, left: 0})}
                    >
                      <p>
                        <h3>Item not available for autobook</h3>
                        <br></br>
                        You may add this to a quote, but the rental company will
                        <br></br>
                        have to approve it before the reservation is confirmed.
                      </p>
                    </ReactTooltip>
                  )}
                </label>

                {
                  showPrice && (
                    <span className='normalText pricing'>
                      ${itemPricingDisplay}
                    </span>
                  )
                }

                {item.bundle &&
                  !product.priceLocked &&
                  product.flatPrices &&
                  product.flatPrices.map((flatPrice) => (
                    <span className='normalText pricing'>
                      {currencyFormatter.format(flatPrice.amount)} / Flat:{' '}
                      {flatPrice.name}
                    </span>
                  ))}

                {item.bundle &&
                  !product.priceLocked &&
                  product.purchasePrice > 0 && (
                    <span className='normalText pricing'>
                      + {currencyFormatter.format(product.purchasePrice)} / Each
                    </span>
                  )}
              </div>
              <div>
                {!cartConfirmation &&
                  !item.parentInventory?.id && (
                    <a
                      className='btnLink remove'
                      onClick={this.handleItemRemove}
                    >
                      Remove
                    </a>
                  )}
              </div>
            </td>
          </tr>
        }

        mobile={
          <tr>
            <td
              className={classnames({
                mobileItemContent: true,
              })}
            >
              {tileView && (
                <figure>
                  {product.pictures[0] ? (
                    <Imgix
                      src={product.pictures[0].imgixUrl}
                      alt={product.name}
                      width={510}
                      height={288}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              )}
              <label id='itemName'>
                {!item.commodity ? item.name : product.name}
              </label>
              {!cartConfirmation &&
                !item.parentInventory?.id && (
                  <a className='btnLink remove' onClick={this.handleItemRemove}>
                    Remove
                  </a>
                )}
              {cartConfirmation || item.parentInventory?.id  ? (
                <div style={{width: "60px"}}>
                  {itemQuantity}
                  <br />
                </div>
              ) : (
                <StandardTextField
                  type='text'
                  meta={{ error: {} }}
                  input={{
                    name: 'quantity',
                    value: item.quantity,
                    onChange: this.handleQuantityUpdate,
                  }}
                />
              )}
              {storefrontShopSetting.shopShowItemPricing &&
                getItemPeriodPrice(item, productType, locale, location)}
            </td>
          </tr>
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { location, parsedStorefrontSettings } = state.locations;
  const { storefrontShopSetting } = parsedStorefrontSettings;
  const { event } = state.cart
  const { availability } = state;
  return { location, availability, event, storefrontShopSetting };
};

export default connect(mapStateToProps, actions)(CartItem);
