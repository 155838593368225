/* eslint-disable jsx-a11y/anchor-is-valid */

// Is this component still being used??
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import * as actions from 'Actions';
import SearchFilter from 'Utils/SearchFilter';
import DatePicker from 'Utils/DatePicker';
import TimePicker from 'Utils/TimePicker';
import { ArrowLeft, Items, Info, Alert } from 'Utils/SvgIcons';
import { combineDateAndTime } from 'HelperFunctions/general';
import {
  showSelectedPrice,
  hasSelectedFlatPrice,
  getSelectedFlatPrice,
} from 'HelperFunctions/rental_items';
import { getFirstAvailablePricingType } from 'HelperFunctions/rentals';
import { imgixUrl } from 'HelperFunctions/imgix';
import NumberInput from 'Utils/NumberInput';
import { apiToSingularRoot } from 'Constants/displayConstants';
import Modal from 'Utils/Modal';
import SuggestionTable from './SuggestionTable';
import Magnifier from 'Utils/images/Magnifier';
import Event from 'Utils/images/Event';
import PageMetaData from 'Utils/PageMetaData';
import CurrencyLabel from 'Utils/CurrencyLabel';
import classnames from 'classnames';
import OverbookingTooltip from './OverbookingTooltip';
import {
  getNumberAvailable,
  checkInventoryObjectAvailabilityLoading,
} from 'HelperFunctions/availability';
import CartItem from '../cart/CartItem';
import SelectDatesModal from './SelectDatesModal';
import sanitizeHtml from 'sanitize-html';
import { updatedInventoryOnCartAfterDefiningNewQuantity } from 'HelperFunctions/inventory';


const priceTypeToBreakpoint = {
  hourlyPrice: 'hours',
  halfDayPrice: 'halfDay',
  dailyPrice: 'days',
  weeklyPrice: 'weeks',
  monthlyPrice: 'months',
};
// Third shopping page
const AvailableItem = (productType) => {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);
      
      const quantityOnCart = props.event.inventoryOnCart.find(inv => inv.itemId === parseInt(props.product.id) && inv.itemType === props.product.productType)?.quantity || 0;

      this.state = {
        quantity: quantityOnCart,
        submitted: false,
        breakpoint: '',
        addButtonText: productType === 'bundles' ? 'Add Bundle' : 'Add Item',
        open: false,
        suggestionModalOpen: false,
        selectDatesModalOpen: false,
        linkedItems: false,
      };

      this.handleChange = this.handleChange.bind(this);
      this.toggleOpen = this.toggleOpen.bind(this);
    }
    componentDidMount() {
      this.props.fetchProduct(
        apiToSingularRoot[productType],
        this.props.match.params.id,
        this.props.event
      );
    }
    componentDidUpdate(prevProps) {
      if (this.props?.errorCode === 404) {
        this.props.history.push('/shop');
      }
      if (this.props.product.id !== prevProps.product.id) {
        this.props.product.pictures.forEach((p, index) => {
          let event = new Event();
          let magnifier = new Magnifier(event);
          magnifier.attach({
            thumb: `#thumb-${index}`,
            largeWrapper: `preview-${index}`,
            zoom: 2,
          });
        });
      }
    }
    
    componentWillReceiveProps(nextProps) {
      const { product } = nextProps;
      const firstPriceType = Object.keys(priceTypeToBreakpoint).find(
        (objectKey) => product[objectKey] !== undefined
      );
      if (firstPriceType !== undefined) {
        this.setState({
          breakpoint: priceTypeToBreakpoint[firstPriceType],
        });
      }
    }

    handleChange(event) {
      const { name, value } = event.target;
      this.setState({
        [name]: value,
        submitted: false,
      });
    }

    handleEventChange = (e) => {
      const { name, value } = e.target;
      this.props.setCartProps({ [name]: value });
    };

    handleDateChange = (e) => {
      const {
        event,
        inventoryFilter,
        reloadInventory,
        setCartProps,
        convertStorefrontToV2PayloadAndReloadStore
      } = this.props;
      const { name, value } = e.target;
  
      let newEvent = Object.assign(
        {
          ...event,
          [name]: value,
        },
        name === 'eventStart' &&
          (!event.eventEnd || event.eventEnd === '') && { eventEnd: value },
        name === 'eventEnd' &&
          (!event.eventStart || event.eventStart === '') && { eventStart: value }
      );
  
      // Adjust eventEnd if eventStart is after eventEnd
      const eventStartDateTime = combineDateAndTime(
        new Date(newEvent.eventStart),
        new Date(newEvent.eventStartTime)
      );
      let eventEndDateTime = combineDateAndTime(
        new Date(newEvent.eventEnd),
        new Date(newEvent.eventEndTime)
      );
  
      if (eventStartDateTime >= eventEndDateTime) {
        eventEndDateTime = new Date(
          eventStartDateTime.getTime() + 60 * 60 * 1000
        );
      }
  
      newEvent.eventStartTime = eventStartDateTime
      newEvent.eventEndTime = eventEndDateTime
      newEvent.eventStart = eventStartDateTime
      newEvent.eventEnd = eventEndDateTime
      newEvent.cartHasChanged = true

      setCartProps(newEvent);
      convertStorefrontToV2PayloadAndReloadStore();

      reloadInventory({
        ...inventoryFilter,
        eventStart: eventStartDateTime,
        eventEnd: eventEndDateTime,
      });
      this.props.fetchProduct(
        apiToSingularRoot[productType],
        this.props.match.params.id,
        newEvent
      );
    };

    handleAddInventoryToQuote = () => {
      const { setCartProps, setErrors, event, product } = this.props;
      const { inventoryOnCart } = event;
      const { quantity } = this.state;
  
      if (quantity > 0) {
        const newInventoryOnCart = updatedInventoryOnCartAfterDefiningNewQuantity(inventoryOnCart, product, quantity, !event.editingEvent)
    
        setCartProps({ inventoryOnCart: newInventoryOnCart });
        this.setState({ submitted: true });
        localStorage.setItem('cartItems', JSON.stringify(inventoryOnCart));
      } else {
        setErrors('Amount must be greater than zero.');
      }
    }

    toggleOpen() {
      this.setState({
        open: !this.state.open,
      });
    }
    handleOpenSuggestionModal = () => {
      this.setState({ suggestionModalOpen: true });
    };

    handleCloseSuggestionModal = () => {
      this.setState({ suggestionModalOpen: false });
    };
    renderPricing(product, location) {
      if (!product) {
        return null;
      }

      if (!location) {
        location = this.props.location;
      }

      if(!product.showPriceStorefront) {
        return <div className='pricing'>Price On Request</div>;
      }

      if (hasSelectedFlatPrice(product, productType)) {
        const selectedFlatPrice = getSelectedFlatPrice(product);
        if (selectedFlatPrice) {
          return (
            <div className='pricing'>
              <div>
                <CurrencyLabel value={selectedFlatPrice.amount} />
                <label>/ Flat: {selectedFlatPrice.name}</label>
              </div>
            </div>
          );
        }
      } else {
        const firstAvailablePricingType = getFirstAvailablePricingType(
          product,
          productType
        );

        if (firstAvailablePricingType) {
          return (
            <div className='pricing'>
              {product.hourlyPrice && (
                <div>
                  <CurrencyLabel value={product.hourlyPrice} />
                  <label>/ Hour</label>
                </div>
              )}
              {product.pricing && (
                <div>
                  <CurrencyLabel value={product.pricing} />
                  <label>/ Each</label>
                </div>
              )}
              {product.halfDayPrice && (
                <div>
                  <CurrencyLabel value={product.halfDayPrice} />
                  <label>/ Half-Day</label>
                </div>
              )}
              {product.dailyPrice && (
                <div>
                  <CurrencyLabel value={product.dailyPrice} />
                  <label>/ Day</label>
                </div>
              )}
              {product.weeklyPrice && (
                <div>
                  <CurrencyLabel value={product.weeklyPrice} />
                  <label>/ Week</label>
                </div>
              )}
              {product.monthlyPrice && (
                <div>
                  <CurrencyLabel value={product.monthlyPrice} />
                  <label>/ Month</label>
                </div>
              )}

              {productType === 'bundles' &&
                !product.priceLocked &&
                product.flatPrices &&
                product.flatPrices.map((flatPrice, index) => (
                  <div>
                    <CurrencyLabel value={flatPrice.amount} />
                    <label>/ Flat: {flatPrice.name}</label>
                  </div>
                ))}

              {productType === 'bundles' && product.purchasePrice && (
                <div className='fee'>
                  <span>
                    +
                    <CurrencyLabel value={product.purchasePrice} />
                  </span>
                  <label>/ Fee</label>
                </div>
              )}
            </div>
          );
        }
      }

      return <div className='pricing'>Price On Request</div>;
    }
    renderImgixPicture = (picture, index) => {
      const { src, srcSet } = imgixUrl({
        src: picture.imgixUrl,
        width: 1020,
        height: 576,
      });
      return (
        <img
          id={`thumb-${index}`}
          height={288}
          width={510}
          alt={src}
          src={src}
          srcSet={srcSet}
        />
      );
    };

    userHasNotPickedStartAndEndDateAndTimes = () => {
      const {
        event: { eventStart, eventStartTime, eventEnd, eventEndTime },
      } = this.props;

      return !eventStart || !eventStartTime || !eventEnd || !eventEndTime;
    };

    closeSelectDatesModal = () => {
      this.setState({ selectDatesModalOpen: false });
    };

    handleItemChange = (
      e,
      itemIndex,
      handleCommodityQuantity,
      recurse = false,
      newItems = [],
      index = 0,
      commodityRelationship
    ) => {
      const { event, setEventProperties, location } = this.props;
      const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
      const { name, value } = e.target;

      //Before newItems update
      let commodityItemIndex = itemIndex + index + 1;
      let currentIndex = recurse ? commodityItemIndex : itemIndex;
      newItems = recurse ? newItems : event.items.slice();
      let commodityQuantity = recurse
        ? commodityRelationship.quantity * value
        : 0;

      newItems[currentIndex] = recurse
        ? {
            ...newItems[currentIndex],
            [name]: toNumber(commodityQuantity),
          }
        : {
            ...newItems[currentIndex],
            [name]: toNumber(value),
          };

      //After newItems update
      let currentCommodityRelationship = recurse
        ? newItems[itemIndex].product?.commodityProductRelationships[index]
        : '';
      let currentCommodity = recurse
        ? newItems[itemIndex].product?.commodityProductRelationships[index]
            ?.commodity
        : '';
      let currentItem = recurse
        ? currentCommodity
        : newItems[itemIndex].product;

      const selected = showSelectedPrice(
        currentItem,
        recurse
          ? currentCommodityRelationship.quantity * newItems[itemIndex].quantity
          : newItems[itemIndex].quantity,
        combineDateAndTime(eventStart, eventStartTime),
        combineDateAndTime(eventEnd, eventEndTime),
        'items',
        location
      );

      newItems[currentIndex] = {
        ...newItems[currentIndex],
        selectedPrice: selected.total,
        selectedRate: selected.rate,
        duration: selected.duration,
        period: selected.period,
      };

      if (
        newItems[itemIndex].product.commodityProductRelationships &&
        !recurse
      ) {
        this.handleCommodityChange(
          e,
          itemIndex,
          handleCommodityQuantity,
          'item',
          newItems[itemIndex].product.commodityProductRelationships,
          newItems
        );
      }

      if (newItems[itemIndex].product.addOnProductRelationships && !recurse) {
        this.handleCommodityChange(
          e,
          itemIndex,
          handleCommodityQuantity,
          'addOn',
          newItems[itemIndex].product.addOnProductRelationships,
          event.addOns.slice()
        );
      }

      setEventProperties({
        items: newItems,
      });
    };

    handleItemRemove = (itemIndex) => {
      const { event, setEventProperties } = this.props;
      let newItems = [...event.items];
      newItems.splice(itemIndex, 1);

      setEventProperties({ items: newItems });
    };

    render() {
      const { product, history, event, storefrontShopSetting, availability } =
        this.props;
      const { eventStart, eventStartTime, eventEnd, eventEndTime } = event;
      const { quantity, submitted, addButtonText, suggestionModalOpen } =
        this.state;
      const location = Object(product.location);

      const isAvailabilityLoading = checkInventoryObjectAvailabilityLoading(
        availability,
        product.id,
        productType
      );

      // TODO: Memoize it so it doesn't have to run every time the input value changes
      const numberAvailable = getNumberAvailable(
        availability,
        product.id,
        productType
      );

      const overbookingAllowed =
        location.overbookingOnSf == 'allowed' ||
        (location.overbookingOnSf == 'manually' && product.allowOverbooking);

      const overbookingActionEnabled =
        quantity > numberAvailable && !overbookingAllowed;

      const sanitizedData = (data) => ({
        __html: sanitizeHtml(data),
      });

      return (
        <div className='product'>
          <PageMetaData setting={product} isProduct />
          <header>
            <SearchFilter />
            <a className='btn outline' onClick={history.goBack}>
              <ArrowLeft />
              Back
            </a>
          </header>
          <section className='content'>
            <section className='images'>
              {product.pictures !== undefined && product.pictures.length > 0 ? (
                product.pictures.length > 1 ? (
                  <Carousel
                    showArrows={true}
                    showThumbs={true}
                    showStatus={false}
                    infiniteLoop={false}
                    autoPlay={true}
                    interval={8000}
                    key={product.id}
                  >
                    {product.pictures.map((picture, index) => {
                      return (
                        <figure
                          key={picture.id}
                          className='magnifier-thumb-wrapper'
                        >
                          {this.renderImgixPicture(picture, index)}
                        </figure>
                      );
                    })}
                  </Carousel>
                ) : (
                  <figure className='magnifier-thumb-wrapper'>
                    {this.renderImgixPicture(product.pictures[0], 0)}
                  </figure>
                )
              ) : (
                <figure>
                  <Items />
                </figure>
              )}
            </section>

            <section className='summary'>
              {product.pictures &&
                product.pictures.map((picture, index) => (
                  <div
                    className='magnifier-preview loading'
                    id={`preview-${index}`}
                  />
                ))}

              <h3>{product.name}</h3>
              {storefrontShopSetting.shopShowItemPricing &&
                this.renderPricing(product)}
              <div className='quantitySelect'>
                <NumberInput
                  disabled={isAvailabilityLoading}
                  type='text'
                  placeholder='Qty'
                  name='quantity'
                  value={quantity}
                  onChange={this.handleChange}
                />
                {quantity > numberAvailable && (
                  <div
                    className={classnames({
                      info: true,
                      overbooking: overbookingActionEnabled,
                    })}
                    data-tip
                    data-for={overbookingAllowed ? 'info' : 'overbooking'}
                    data-event='click'
                    data-event-off='mouseleave'
                  >
                    {overbookingAllowed ? <Info /> : <Alert />}
                  </div>
                )}
                {quantity > numberAvailable && (
                  <OverbookingTooltip
                    allowOverbooking={overbookingAllowed}
                    numberAvailable={numberAvailable}
                  />
                )}
                {submitted ? (
                  <Link className='btn' to='/cart'>
                    Added
                  </Link>
                ) : (
                  <button
                    className='btn'
                    onClick={this.handleAddInventoryToQuote}
                    disabled={overbookingActionEnabled}
                  >
                    {addButtonText}
                  </button>
                )}
              </div>
            </section>

            <section className='details'>
              <p dangerouslySetInnerHTML={sanitizedData(product.description)} />
              {product.sfSubCategories && product.sfSubCategories.length > 0 && (
                <section className='itemTags'>
                  <label>Tags</label>
                  {'  '}
                  <span>
                    {product.sfSubCategories &&
                      product.sfSubCategories.map((ic) => ic.name).join(', ')}
                  </span>
                </section>
              )}

              {productType === 'bundles' &&
                product.showItemsToCustomer &&
                product.productBundleRelationships
                  // .concat(product.accessoryBundleRelationships)
                  .concat(product.addOnBundleRelationships)
                  .map((pbr, index) => (
                    <div key={index}>
                      <figure>
                        {pbr.primaryPhotoUrl !== '' ? (
                          <img
                            src={pbr.primaryPhotoUrl}
                            alt={pbr.primaryPhotoUrl}
                          />
                        ) : (
                          <Items />
                        )}
                      </figure>
                      <strong>
                        x{pbr.quantity} {pbr.name}
                      </strong>
                      <p
                        dangerouslySetInnerHTML={sanitizedData(pbr.description)}
                      />
                    </div>
                  ))}
            </section>

            <section className='misc'>
              <div className='dateRange'>
                <p>Know your event date/time? Set it now.</p>
                <div>
                  <label>Order Start</label>
                  <DatePicker
                    name='eventStart'
                    value={eventStart}
                    onChange={this.handleDateChange}
                  />
                  <TimePicker
                    name='eventStartTime'
                    value={eventStartTime}
                    onChange={this.handleDateChange}
                  />
                </div>
                <div>
                  <label>Order End</label>
                  <DatePicker
                    name='eventEnd'
                    value={eventEnd}
                    onChange={this.handleDateChange}
                    startDate={eventStart ? eventStart : new Date()}
                  />
                  <TimePicker
                    name='eventEndTime'
                    value={eventEndTime}
                    onChange={this.handleDateChange}
                  />
                </div>
              </div>
              <div className='dimensions'>
                {product.sfSubCategories &&
                  product.sfSubCategories.findIndex(
                    (ic) => ic.name === 'Linen' || ic.name === 'Table Top'
                  ) > -1 && (
                    <a onClick={this.handleOpenSuggestionModal}>
                      Need Help With Sizing?
                    </a>
                  )}
                {(product.heightFt || product.height) && (
                  <div>
                    <label>Height:</label>
                    <span>
                      {product.heightFt ? `${product.heightFt}'` : ''}{' '}
                      {product.height ? `${product.height}"` : ''}
                    </span>
                  </div>
                )}
                {(product.lengthFt || product.length) && (
                  <div>
                    <label>Length:</label>
                    <span>
                      {product.lengthFt ? `${product.lengthFt}'` : ''}{' '}
                      {product.length ? `${product.length}"` : ''}
                    </span>
                  </div>
                )}
                {(product.widthFt || product.width) && (
                  <div>
                    <label>Width:</label>
                    <span>
                      {product.widthFt ? `${product.widthFt}'` : ''}{' '}
                      {product.width ? `${product.width}"` : ''}
                    </span>
                  </div>
                )}
                {product.weight && (
                  <div>
                    <label>Weight:</label>
                    <span>{product.weight}lbs</span>
                  </div>
                )}
              </div>
            </section>

            <section className='linkedItems'>
              <h3>Linked Inventory</h3>
              {product.commodityProductRelationships &&
                product.commodityProductRelationships.map((item, itemIndex) => (
                  <CartItem
                    key={itemIndex}
                    period={item.commodity.period}
                    item={item}
                    itemSelectedPrice={item.commodity.selectedPrice}
                    itemIndex={itemIndex}
                    tileView={true}
                    storefrontShopSetting={storefrontShopSetting}
                    onItemChange={this.handleItemChange}
                    onItemRemove={this.handleItemRemove}
                    renderPricing={this.renderPricing}
                    location={location}
                    isCommodity={true}
                  />
                ))}
              {product.addOnProductRelationships &&
                product.addOnProductRelationships.map((purchase, itemIndex) => (
                  <CartItem
                    key={itemIndex}
                    item={purchase}
                    itemSelectedPrice={purchase.addOn?.selectedPrice}
                    itemIndex={itemIndex}
                    tileView={true}
                    storefrontShopSetting={storefrontShopSetting}
                    onItemChange={this.handleItemChange}
                    onItemRemove={this.handleItemRemove}
                    renderPricing={this.renderPricing}
                    location={location}
                    isCommodity={true}
                  />
                ))}
            </section>
          </section>
          <Modal
            className='suggestionTable'
            title='Table/Linen Suggestions'
            open={suggestionModalOpen}
            toggle={this.handleCloseSuggestionModal}
          >
            <SuggestionTable />
          </Modal>
          <SelectDatesModal
            onClick={this.closeSelectDatesModal}
            isOpen={this.state.selectDatesModalOpen}
            onToggle={this.closeSelectDatesModal}
          />
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    const product = state.products[apiToSingularRoot[productType]];
    const errorCode = state.products?.errorCode;
    const {
      location,
      parsedStorefrontSettings: { storefrontShopSetting },
    } = state.locations;
    const { event } = state.cart;
    const { inventoryFilter } = state.products;
    const { availability } = state;
    return {
      product,
      errorCode,
      location,
      storefrontShopSetting,
      event,
      inventoryFilter,
      availability,
    };
  };
  return connect(mapStateToProps, actions)(WrappedComponent);
};

export default AvailableItem;
