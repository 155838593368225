import {
  SET_CART_PROPS,
  FETCH_EVENT_FAILURE
} from "Constants/redux";
import axios from "axios";
import { getAuthToken } from "Api";
import { setErrors } from "./SnackbarActions";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

export const fetchStorefrontEditPayload = id => {
  return (dispatch, getState) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${id}/translate_rental_to_storefont`, {
        headers: getAuthToken()
      })
      .then(response => {
       const { location } = getState().locations;
        dispatch({
          type: SET_CART_PROPS,
          payload: {...response.data, editingEvent: true, location }
        })
        getState();
      })
      .catch(error => {
        dispatch(setErrors("Error fetching event" + error));
      });
  };
}

const build_rails_object = (state) => {
  const { event } = state.cart;

  delete event.cartHasChanged;
  delete event.translatedPayload;
  delete event.billingLines;
  delete event.location;
  event.inventoryOnCart.forEach((item) => {
    delete item.product;
  })

  return {
    ...event,
  };
};

export const editSubmit = onSuccess => {
  return (dispatch, getState) => {
    const { event } = getState().event;
    const { location } = getState().locations;
    const data = build_rails_object(getState());

    dispatch(openLoadingSpinner("Updating rental..."));
    axios
      .patch(
        process.env.REACT_APP_API_DOMAIN + "/api/portal/rentals/" + event.id,
        {
          location_id: location.id,
          rental: data
        },
        { headers: getAuthToken() }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        onSuccess();
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const requestChanges = onSuccess => {
  return (dispatch, getState) => {
    const { event } = getState().cart;
    const afterAttributes = build_rails_object(getState());
    const { location } = getState().locations;
    const data = {
      after_attributes: { ...afterAttributes, location_id: location.id },
    };

    dispatch(openLoadingSpinner("Requesting changes..."));
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${event.id}/request_changes`,
        data,
        { headers: getAuthToken() }
      )
      .then(response => {
        dispatch(closeLoadingSpinner());
        onSuccess(response.data.rental);
      })
      .catch(error => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
      });
  };
};

export const calculateDefaultDeliveryPrice = ({ event, onSuccess }) => {
  return (dispatch, getState) => {
    const { location } = getState().locations;
    const {
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressCountry,
      items,
      rentalBundles
    } = event;

    if (event.deliveryType === "customer_pick_up") {
      onSuccess(0);
      return;
    }

    if (
      deliveryAddressCity &&
      deliveryAddressLocale &&
      deliveryAddressStreetAddress1 &&
      deliveryAddressPostalCode &&
      deliveryAddressCountry &&
      (items.length > 0 || rentalBundles.length > 0)
    ) {
      let sub_total = 0;
      for (let item of items) {
        sub_total += Number(item.selectedPrice);
      }
      for (let bundle of rentalBundles) {
        sub_total += Number(bundle.selectedPrice);
      }
      axios
        .post(
          process.env.REACT_APP_API_DOMAIN +
            "/api/portal/delivery_setting/calculate_delivery_cost",
          {
            location_id: location.id,
            calculation: {
              sub_total: sub_total,
              street_address_1: deliveryAddressStreetAddress1,
              street_address_2: deliveryAddressStreetAddress2,
              city: deliveryAddressCity,
              locale: deliveryAddressLocale,
              postal_code: deliveryAddressPostalCode,
              country: deliveryAddressCountry
            }
          }
        )
        .then(response => {
          onSuccess(response.data);
        })
        .catch(error => {
          console.log(error);
          dispatch(setErrors(error.response.data));
        });
    } else {
      const errors = Object.assign(
        {},
        !deliveryAddressStreetAddress1 && {
          checkin: "Please specify a delivery street address."
        },
        !deliveryAddressCity && { city: "Please specify a delivery city." },
        !deliveryAddressLocale && {
          locale: "Please specify a delivery state."
        },
        !deliveryAddressPostalCode && {
          postal_code: "Please specify a delivery zip code."
        },
        !deliveryAddressCountry && {
          country: "Please specify a delivery country."
        },
        items.length === 0 &&
          rentalBundles.length === 0 && { items: "Must select items to rent." }
      );
      dispatch(setErrors(errors));
    }
  };
};
