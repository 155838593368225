/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as actions from 'Actions';
import DatePicker from 'Utils/DatePicker';
import TimePicker from 'Utils/TimePicker';
import StandardTextField from 'Utils/redux_form_inputs/StandardTextField';
import StandardTextareaField from 'Utils/redux_form_inputs/StandardTextareaField';
import { CartOutline, ListView, ImageView, CartCheck } from 'Utils/SvgIcons';
import { combineDateAndTime, toNumber } from 'HelperFunctions/general';
import CartItem from './CartItem';
import PhoneInputField from 'Utils/redux_form_inputs/PhoneInputField';
import { history } from 'Components/Routes';
import { getAuthToken } from "Api";
import axios from 'axios';

class CartItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedEvent: {},
      eventLoaded: false
    };
  }

  componentDidUpdate() {
    this.loadItemsFromNewEvent();
  }

  loadItemsFromNewEvent = () => {
    // After submitting the cart, the cart is cleared, and the items disappear
    // This component is also used to show the items in the cart after cart submission
    // Therefore, to show these items, we load the items from the newEvent which contains the token of the submitted rental
    // Then, we set the translated event information on this component to display the items here and on the CartItem component
    const { newEvent, loadItemsFromEvent } = this.props;
    if (loadItemsFromEvent && newEvent.token && !this.state.eventLoaded ) {
      axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/portal/rentals/${newEvent.token}/translate_rental_to_storefont?rental_id=${newEvent.id}`)
      .then(response => {
        this.setState({ loadedEvent: response.data, eventLoaded: true });
        this.forceUpdate();
      })
      .catch(error => {
        dispatch(setErrors("Error fetching event" + error));
      });
    }
  };

  handleDataChange = (event) => {
    const { setCartProps } = this.props;
    const { name, value } = event.target;
    setCartProps({ [name]: value });
  }

  handleDateFocus = (e) => {
    const { value } = e.target;
    if (value !== '') return;
    this.handleDateChange(e);
  };

  handleDateChange = (e) => {
    const {
      event,
      inventoryFilter,
      reloadInventory,
      setCartProps,
      convertStorefrontToV2PayloadAndReloadStore
    } = this.props;
    const { name, value } = e.target;

    let newEvent = Object.assign(
      {
        ...event,
        [name]: value,
      },
      name === 'eventStart' &&
        (!event.eventEnd || event.eventEnd === '') && { eventEnd: value },
      name === 'eventEnd' &&
        (!event.eventStart || event.eventStart === '') && { eventStart: value }
    );

    // Adjust eventEnd if eventStart is after eventEnd
    const eventStartDateTime = combineDateAndTime(
      new Date(newEvent.eventStart),
      new Date(newEvent.eventStartTime)
    );
    let eventEndDateTime = combineDateAndTime(
      new Date(newEvent.eventEnd),
      new Date(newEvent.eventEndTime)
    );

    if (eventStartDateTime >= eventEndDateTime) {
      eventEndDateTime = new Date(
        eventStartDateTime.getTime() + 60 * 60 * 1000
      );
    }

    newEvent.eventStartTime = eventStartDateTime
    newEvent.eventEndTime = eventEndDateTime
    newEvent.eventStart = eventStartDateTime
    newEvent.eventEnd = eventEndDateTime
    newEvent.cartHasChanged = true

    setCartProps(newEvent);
    convertStorefrontToV2PayloadAndReloadStore();

    // Reload inventory with new event dates
    const updatedEventStartDateTime = combineDateAndTime(
      newEvent.eventStart,
      newEvent.eventStartTime
    );
    const updatedEventEndDateTime = combineDateAndTime(
      newEvent.eventEnd,
      newEvent.eventEndTime
    );

    reloadInventory({
      ...inventoryFilter,
      eventStart: updatedEventStartDateTime,
      eventEnd: updatedEventEndDateTime,
    });
  };

  componentDidMount() {
    const { convertStorefrontToV2PayloadAndReloadStore } = this.props;
    convertStorefrontToV2PayloadAndReloadStore();
  }

  redirectToShopPage = () => {
    history.push('/shop');
  };

  render() {
    const {
      event,
      tileView,
      showTiles,
      hideTiles,
      errors,
      authenticated,
      cartConfirmation,
      itemsOnly,
      loadItemsFromEvent,
    } = this.props;

    const { loadedEvent } = this.state;

    const {
      eventStart,
      eventStartTime,
      eventEnd,
      eventEndTime,
      customerContactPhone,
      customerFirstName,
      customerLastName,
      customerEmail,
      additionalOrderInfo,
      inventoryOnCart,
    } = event;
    //Kiara Note: lines 536-582 need to be removed, but removing it makes it so linked purchases
    //aren't removed from the order when the main item is removed.

    const inventoryToShow = (loadItemsFromEvent ? loadedEvent : event)?.inventoryOnCart?.filter(item => item._destroy !== '1') || [];

    return (
      <section
        className={classnames({
          itemForm: true,
          noItems: inventoryOnCart.length === 0,
        })}
      >
        {inventoryToShow.length === 0 && !loadItemsFromEvent ? (
          <div className='prompt'>
            <CartOutline />
            <p>You haven’t added anything to your cart.</p>
            <Link className='btn' to='/shop'>
              Shop Now
            </Link>
          </div>
        ) : (
          <div>
            {
              !itemsOnly && (
                <div className='details'>
              <div className='form' id='cartForm'>
                {
                  !event.editingEvent && (
                    <>
                      <h4>Contact Information</h4>
                      <div className='fields'>
                        <StandardTextField
                          type='text'
                          meta={{ touched: true, error: errors['name'] }}
                          placeholder='Rental Name*'
                          input={{
                            name: 'name',
                            value: event.name,
                            onChange: this.handleDataChange,
                          }}
                        />
                      </div>
                    </>
                  )
                }
                <div className='fields dateRange'>
                  <div>
                    <label>Order Start*</label>
                    <DatePicker
                      name='eventStart'
                      value={eventStartTime}
                      onBlur={this.handleDateFocus}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error:
                          errors['eventStartDateTime'] || errors['eventStart'],
                      })}
                    />
                    <TimePicker
                      name='eventStartTime'
                      value={eventStartTime}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error:
                          errors['eventStartDateTime'] ||
                          errors['eventStartTime'],
                      })}
                    />
                  </div>
                  <div id='orderEnd'>
                    <label>Order End*</label>
                    <DatePicker
                      name='eventEnd'
                      value={eventEndTime}
                      onBlur={this.handleDateFocus}
                      onChange={this.handleDateChange}
                      startDate={eventStart ? eventStart : new Date()}
                      className={classnames({
                        error: errors['eventEndDateTime'] || errors['eventEnd'],
                      })}
                    />
                    <TimePicker
                      name='eventEndTime'
                      value={eventEndTime}
                      onChange={this.handleDateChange}
                      className={classnames({
                        error: errors['eventEndDateTime'] || errors['eventEnd'],
                      })}
                    />
                  </div>
                </div>
                {!authenticated && (
                  <div>
                    <div className='fields'>
                      <StandardTextField
                        type='text'
                        meta={{
                          touched: true,
                          error: errors['firstName'],
                        }}
                        placeholder='First Name*'
                        className='med'
                        input={{
                          name: 'customerFirstName',
                          value: customerFirstName,
                          onChange: this.handleDataChange,
                        }}
                      />
                      <StandardTextField
                        type='text'
                        meta={{
                          touched: true,
                          error: errors['lastName'],
                        }}
                        placeholder='Last Name*'
                        className='med'
                        input={{
                          name: 'customerLastName',
                          value: customerLastName,
                          onChange: this.handleDataChange,
                        }}
                      />
                    </div>
                    <div className='fields' id='unAuthEmailPhone'>
                      <StandardTextField
                        type='text'
                        placeholder='Email Address*'
                        meta={{
                          touched: true,
                          error: errors['customerEmail'],
                        }}
                        input={{
                          name: 'customerEmail',
                          value: customerEmail,
                          onChange: this.handleDataChange,
                        }}
                      />
                      <PhoneInputField
                        meta={{
                          touched: true,
                          error: errors['customerContactPhone'],
                        }}
                        input={{
                          name: 'customerContactPhone',
                          value: customerContactPhone,
                          onChange: this.handleDataChange,
                        }}
                      />
                    </div>
                  </div>
                )}
                {authenticated && !event.editingEvent && (
                  <div className='fields'>
                    <label>Contact Phone Number </label>
                    <PhoneInputField
                      meta={{
                        touched: true,
                        error: errors['customerContactPhone'],
                      }}
                      input={{
                        name: 'customerContactPhone',
                        value: customerContactPhone,
                        onChange: this.handleDataChange,
                      }}
                    />
                  </div>
                )}
                {
                  !event.editingEvent && (
                    <>
                      <div className='fields'>
                        <StandardTextareaField
                          meta={{}}
                          placeholder='Additional Order Info/Notes'
                          input={{
                            name: 'additionalOrderInfo',
                            value: additionalOrderInfo,
                            style: { resize: 'none' },
                            onChange: this.handleDataChange,
                          }}
                        />
                      </div>
                    </>
                  )
                }
              </div>
                </div>
              )
            }
            <div className='items'>
              <h4>Items</h4>
              <div className='tileToggle'>
                <a
                  onClick={hideTiles}
                  className={classnames({
                    active: !tileView,
                  })}
                >
                  <ListView />
                </a>
                <a
                  onClick={showTiles}
                  className={classnames({
                    active: tileView,
                  })}
                >
                  <ImageView />
                </a>
              </div>
              <div className='companyItems'>
                <table
                  className={classnames({
                    grid: true,
                    thumbnail: tileView,
                  })}
                >
                  <tbody className='cartItemList'>
                    {inventoryToShow.map((item, itemIndex) => (
                      <CartItem
                        key={itemIndex}
                        period={item.period}
                        item={item}
                        itemSelectedPrice={item.selectedPrice}
                        itemIndex={itemIndex}
                        tileView={tileView}
                        cartConfirmation={cartConfirmation}
                        loadItemsFromEvent={loadItemsFromEvent}
                        loadedEvent={loadedEvent}
                    />
                    ))}
                    {
                      !cartConfirmation && (
                        <div className='addItemBtn'>
                          <a onClick={this.redirectToShopPage} className='btn'>
                            Add More Items To Order
                          </a>
                        </div>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    location,
    parsedStorefrontSettings: { storefrontShopSetting },
  } = state.locations;
  const { inventoryFilter, tileView } = state.products;
  const { errors } = state.dashboard;
  const { authenticated } = state.auth;
  const { event, newEvent } = state.cart;
  
  return {
    location,
    storefrontShopSetting,
    inventoryFilter,
    tileView,
    errors,
    authenticated,
    event,
    newEvent
  };
};

export default connect(mapStateToProps, actions)(CartItems);
