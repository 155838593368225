import { extractKeysFromObject } from 'HelperFunctions/general';

const keysToExtractFromProudct = ['id', 'productType', 'commodityProductRelationships', 'allowOverbookingOnSf', 'name', 'priceLocked', 'flatPrices', 'purchasePrice', 'pictures', 'showPriceStorefront', 'parentInventory']

export const updatedInventoryOnCartAfterDefiningNewQuantity = (inventoryOnCart, inventory, quantity, addLinkedItems = true) => {
  // How to use this function:
  // Pass the current inventoryOnCart, the inventory object, the quantity to set, and if you want to add the linked items
  // To delete items, pass a quantity of 0 
  // If the item is being added as a linked item to another item, pass parentInventory as the originator of the link
  // This function returns the desired final state of the inventoryOnCart array

  // Currently, the behavior on storefront is that if you set the quantity of an item A, and it is linked to another item B,
  // you will override the quantity of item B to the quantity of item A times the linked quantity ratio
  // This might change in the future to be incremental, but is being skipped now due to UI/UX reasons

  if (quantity < 0) return inventoryOnCart;

  const parentInventory = inventory.parentInventory;
  let inventoryOnCartCopy = [...inventoryOnCart ];

  const isMatchingInventory = (cartItem, inventory, parentInventory) => {
    const isSameInventory = cartItem.itemId === inventory.id && cartItem.itemType === inventory.productType;
    const isSameParentInventory = cartItem.parentInventory?.id === parentInventory?.id &&
                                  cartItem.parentInventory?.productType === parentInventory?.productType;
    
    return isSameInventory && isSameParentInventory;
  };
  
  const currentInventoryOnCart = inventoryOnCartCopy.find(item => isMatchingInventory(item, inventory, parentInventory))

  if (currentInventoryOnCart) {
    currentInventoryOnCart.quantity = Number(quantity)
    currentInventoryOnCart.destroy = '0'
  } else {
    inventoryOnCartCopy.push({
      itemType: inventory.productType,
      itemId: Number(inventory.id),
      quantity: Number(quantity),
      parentInventory: extractKeysFromObject(parentInventory, keysToExtractFromProudct),
    });
  }

  if (addLinkedItems && quantity >= 0 && !parentInventory?.id) {
    (inventory.commodityProductRelationships || []).forEach((linkedItem) => {
      inventoryOnCartCopy = updatedInventoryOnCartAfterDefiningNewQuantity(inventoryOnCartCopy, {...linkedItem.commodity, parentInventory: inventory}, quantity * linkedItem.quantity);
    }) 
  }

  inventoryOnCartCopy.forEach((inventoryItem) => {
    if (inventoryItem.quantity === 0) {
      inventoryOnCartCopy._destroy = 1;
    }
  })

  // Reorder the items by placing the items linked to a parent item right after it
  inventoryOnCartCopy = placeItemsAfterParents(inventoryOnCartCopy)

  // Reorder items by placing them first by items, then bundles, then addons
  const order = { 'items': 0, 'bundles': 1, 'addons': 2 };
  inventoryOnCartCopy.sort((a, b) => {
    return order[a.itemType] - order[b.itemType];
  })

  return inventoryOnCartCopy;
};

export const updatedInventoryOnCartAfterRemovingInventory = (inventoryOnCart, inventory) => {
  const inventoryCopy = [...(inventoryOnCart || [])];
  const indexOnCart = findInventoryIndex(inventoryCopy, inventory);

  if (indexOnCart !== -1) {
    inventoryCopy.splice(indexOnCart, 1);
  }

  // Remove any linked items associated with the removed inventory
  const linkedItems = findLinkedItems(inventoryCopy, inventory);
  linkedItems.forEach(linkedItem => removeLinkedItem(inventoryCopy, linkedItem));

  return inventoryCopy;
};

const findInventoryIndex = (cart, inventory) =>
  cart.findIndex(item => item.itemId === inventory.itemId && item.itemType === inventory.itemType);

const findLinkedItems = (cart, inventory) =>
  cart.filter(item => item.parentInventory?.id === inventory.itemId && item.parentInventory?.productType === inventory.itemType);

const removeLinkedItem = (cart, linkedItem) => {
  const index = cart.findIndex(
    item =>
      item.itemId === linkedItem.itemId &&
      item.itemType === linkedItem.itemType &&
      item.parentInventory?.id
  );

  if (index !== -1) {
    cart.splice(index, 1);
  }
};


const placeItemsAfterParents = (inventoryOnCart) => {
  return inventoryOnCart.sort((a, b) => {
    if (a.parentInventory?.id === b.itemId) {
      return 1;
    }
    if (b.parentInventory?.id === a.itemId) {
      return -1;
    }
    return 0;
  });
}